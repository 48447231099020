import { writable, derived, get } from 'svelte/store';

export const requestInfo = writable();

export const bookingInfo = writable();

export const blockedReservation = writable();


function setUpGuests() {

	const { subscribe, set, update } = writable([]);

	return {
		subscribe,
		saveGuest: (g) => update(l => {
			let guestIndex = l.findIndex(i => i.assigneeId == g.assigneeId);

			if (guestIndex > -1) {
				l.splice(guestIndex, 1, { ...g });
			} else {
				l.push({ ...g });
			}

			return [...l];
		}),
		removeGuest: (g) => update(l => {
			let guestIndex = l.findIndex(i => i.assigneeId == g.assigneeId);

			if (guestIndex > -1) {
				l.splice(guestIndex, 1);
			}

			return [...l];
		}),
		reset: () => set([])
	}
}

export const guests = setUpGuests();
export const assignees = derived(guests, $guests => {
	let filtered = $guests.filter(g => g.type == 'guest' || g.type == 'both');
	if (filtered.length > 1 && get(requestInfo).bookingCode) {
		return [...filtered, { firstName: "Company", lastName: "" }];
	} else {
		return filtered;
	}
});
export const payors = derived(guests, $guests => $guests.filter(g => g.type == 'payor' || g.type == 'both'));


export const allFees = writable([]);
export const activeFees = derived(allFees, $all => $all.filter((f) => f.checked || !f.optional));


export const _paymentFrequencyDefault = writable();
export const _paymentFrequency = writable();

export const _specialRequests = writable('');