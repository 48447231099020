{#if $_config.leaseAgreementMessage}
	<div class="zenya-send-agreement">
		<div>
			If you are making this reservation on behalf of someone else, send
			this form to them by clicking the button below.
		</div>
		<button
			class="zenya-booking-button zenya-send-lease-open"
			on:click={openModal}>Send</button
		>
	</div>
{/if}

<svelte:head>
	<!-- <link href="//cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet" /> -->
	<script src="{envURL}tinymce/tinymce.min.js">
	</script>
</svelte:head>
{#if modalState}
	<div class="zenya-lease-modal-wrapper" transition:fade>
		<div class="zenya-lease-modal-container">
			<div class="zenya-lease-modal-content">
				<div class="zenya-lease-modal-section">
					<div class="zenya-lease-modal-section-subheader">
						Recipients
					</div>
					<div class="zenya-lease-recipient-list">
						{#each message.setRecipients.concat(additionalRecipients) as r}
							<div class="zenya-lease-recipient">
								{#if message.sendEmailMessage}
									<div>
										{#if r.recipientIsGuest}
											{$bookingInfo.guests[0].email}
											({recipientTypes[
												r.messagerecipienttypeId
											]})
										{:else}
											{r.username}
											({recipientTypes[
												r.messagerecipienttypeId
											]})
										{/if}
									</div>
								{/if}
								{#if message.sendTextMessage}
									<div>
										{#if r.recipientIsGuest}
											{$bookingInfo.guests[0].phone}
										{:else}{r.mobile}{/if}
									</div>
								{/if}
							</div>
						{/each}
					</div>
					<form on:submit|preventDefault={addRecipient}>
						{#if message.sendEmailMessage}
							<label>
								Add Email
								<div style="display: flex;">
									<select
										style="width: 5em; margin-right: 15px;"
										placeholder="Email Type"
										bind:value={newRecipient.messagerecipienttypeId}
									>
										<option value="1">To</option>
										<option value="2">CC</option>
										<option value="3">BCC</option>
									</select>
									<input
										style="flex: 1;"
										type="email"
										placeholder="Email Address"
										bind:value={newRecipient.username}
									/>
								</div>
							</label>
						{/if}
						{#if message.sendTextMessage}
							<label>
								Add Phone
								<div style="display: flex;">
									<input
										style="flex: 3"
										type="tel"
										placeholder="Phone Number"
										bind:value={newRecipient.mobile}
									/>
								</div>
							</label>
						{/if}
						<button
							type="submit"
							class="zenya-booking-button zenya-secondary-button zenya-lease-recipient-add"
							>Add</button
						>
					</form>
				</div>
				<div class="zenya-lease-modal-section">
					<div class="zenya-lease-modal-section-subheader">
						Message Content
					</div>
					{#if message.sendEmailMessage}
						<label>
							Email Subject
							<input
								type="text"
								bind:value={message.emailSubject}
							/>
						</label>
						<!-- <div
							use:quill={options}
							on:text-change={(e) => (content = e.detail)}
							class="zenya-lease-message-editor"
							bind:this={editorContainer} /> -->
						<textarea
							bind:value={message.emailText}
							class="zenya-lease-message-editor"
							bind:this={editorContainer}
						/>
					{/if}
					{#if message.sendTextMessage}
						<label>
							SMS Message
							<textarea
								class="zenya-lease-message-text"
								bind:value={message.messageText}
							/>
						</label>
					{/if}
				</div>
			</div>
			<div class="zenya-lease-modal-actions">
				<button
					on:click={cancel}
					class="zenya-cancel-button zenya-send-lease-popup-cancel"
					>Cancel</button
				>
				<div class="zenya-flex-spacer" />
				<button
					class="zenya-booking-button zenya-send-lease-popup-send"
					on:click={sendMessage}>Send</button
				>
			</div>
		</div>
	</div>
{/if}

<script>
	import { fade } from "svelte/transition";
	// import { quill } from "svelte-quill";
	import { afterUpdate, getContext } from "svelte";
	import { _loading, route } from "./RoutingStore";
	import { _config } from "./ConfigStore";
	import { bookingInfo } from "./BookingStore";

	let editorContainer;

	let post = getContext("fetch");
	let envURL = getContext("envURL");

	/// SENDING LEASE AGREEMENT
	let editor;
	let modalState = false;
	let message;

	let newRecipient = {};
	let additionalRecipients = [];

	/// META DATA
	let recipientTypes = ["", "To", "CC", "BBC"];

	afterUpdate(() => {
		if (editorContainer && !editor) {
			tinymce.init({
				selector: ".zenya-lease-message-editor",
				menubar: false,
				setup: (created) => {
					editor = created;
				},
			});
		}
	});

	async function openModal() {
		// console.log($bookingInfo);
		_loading.set(true);
		let postResult = await post("getLeaseMessage", {
			customerGuestInstanceId: $bookingInfo.guestId,
			customerMessageId: $_config.leaseAgreementMessage,
		});
		_loading.set(false);
		let result = await postResult.json();

		if (postResult.ok) {
			message = result.data;
			modalState = true;
		} else {
			console.error(result);
		}
	}

	function cancel() {
		modalState = false;
	}

	function addRecipient() {
		additionalRecipients = [...additionalRecipients, { ...newRecipient }];
		newRecipient = {};
	}

	async function sendMessage() {
		let sendMessage = {
			customerGuestInstanceId: $bookingInfo.guestId,
			customerMessageId: message.customerMessageId,
			messageSentOn: message.messageSentOn,
			emailSubjectEdited: message.emailSubject,
			emailTextEdited: editor.getContent(),
			messageTextEdited: message.messageText,
			conciergeTextEdited: message.conciergeText,
			additionalRecipients: additionalRecipients,
		};

		_loading.set(true);
		let postResult = await post("sendLeaseMessage", sendMessage);
		_loading.set(false);
		let result = await postResult.json();

		if (postResult.ok) {
			cancel();
			bookingInfo.set({ ...$bookingInfo, sentLeaseAgreement: true });

			/// PROCESS PAYMENTS -> SAVE PAYMENT INFO TO PAYORS
			_loading.set(true);
			let paymentPostResult = await post("processPayments", {
				payments: $bookingInfo.paymentObjects,
				sentLeaseAgreement: true,
			});
			_loading.set(false);

			if (paymentPostResult.ok) {
				let paymentResult = await paymentPostResult.json();
				bookingInfo.set({ ...$bookingInfo, ...paymentResult.data });

				/// SHOW CONFIRMATION
				route.set("confirmation");
			} else {
				console.error(await paymentPostResult.json());
			}
		} else {
			console.error(result);
		}
	}
</script>

<style lang="scss">
	/// MODAL
	.zenya-send-agreement {
		padding: 10px;
		background: whitesmoke;
		border: 1px solid lightgray;
		border-radius: 4px;
		margin: 10px;
		font-size: 1.1em;

		& > div {
			margin-bottom: 10px;
		}
	}
	.zenya-lease-modal-wrapper {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: rgba(255, 255, 255, 0.6);
		background: rgba(0, 0, 0, 0.4);
		display: flex;
		align-items: center;
		justify-content: center;
		backdrop-filter: blur(2px);

		.zenya-lease-modal-container {
			padding: 10px;
			background-color: white;
			border-radius: 4px;
			box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.7);
			min-width: 50vw;
			min-height: 50vh;
			max-width: 90vw;
			max-height: 90vh;
			display: flex;
			flex-direction: column;

			.zenya-lease-modal-content {
				flex: 1;
				overflow-y: auto;

				.zenya-lease-modal-section {
					border: 1px solid lightgray;
					padding: 10px;
					border-radius: 4px;
					margin-bottom: 15px;

					.zenya-lease-modal-section-subheader {
						font-weight: bolder;
						font-size: 1.2em;
						margin-bottom: 0.5em;
					}

					label {
						margin-bottom: 10px;
					}

					.zenya-lease-recipient-list {
						margin-bottom: 10px;
						padding-bottom: 6px;
						font-size: 0.9em;
						border-bottom: 1px solid lightgray;

						.zenya-lease-recipient {
							line-height: 1.4em;
						}
					}
				}
			}
			.zenya-lease-modal-actions {
				margin-top: 10px;
				display: flex;
				flex-direction: row;

				.zenya-flex-spacer {
					flex: 1;
				}

				.zenya-cancel-button {
					background: white !important;
					color: red !important;
					border: 1px solid red !important;
					border-radius: 4px;
				}
			}
		}
	}
</style>
