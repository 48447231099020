<svelte:head
	><script src="https://js.stripe.com/v3/">
	</script></svelte:head
>

<div class="booking-layout">
	<form
		class="payment-form booking-form"
		on:submit|preventDefault={submitBooking}
	>
		<!-- RESERVATION TIMER -->
		<div
			class="zenya-booking-reserved-timer"
			class:zenya-panel-info={$timer > 0}
			class:zenya-panel-warning={$timer < 1}
		>
			{#if $timer > 0}
				<span>This {$_config.roomAlt} is temporarily reserved for</span>
				<b
					>{reservedMinutes}{reservedMinname}
					{reservedSeconds}s</b
				><span>, after which we cannot guarantee availability.</span>
			{:else}The temporary reservation has expired.{/if}
		</div>
		<!-- HANDLE GUESTS -->
		<div class="booking-section">
			<BookingGuestsComponent bind:this={bookingGuests} />
		</div>

		{#if $assignees.length > 0}
			{#if $_config.includeAgentFields == "Include"}
				<div class="booking-section">
					<h2>Agent</h2>

					<!-- AGENT FIELDS -->
					<div class="zenya-booking-form-row zenya-booking-row-split">
						<div class="zenya-booking-field">
							<input
								id="bookingFormField-agentFirstName"
								data-tid="elements_bookingForm.form.agentFirstName_placeholder"
								class="input"
								type="text"
								bind:value={formData.agentFirstName}
								autocomplete="agentFirstName"
							/>
							<label
								for="bookingFormField-agentFirstName"
								data-tid="elements_bookingForm.form.agentFirstName_label"
							>
								Agent First Name
							</label>
							<div class="baseline" />
						</div>
						<div class="zenya-booking-field">
							<input
								id="bookingFormField-agentLastName"
								data-tid="elements_bookingForm.form.agentLastName_placeholder"
								class="input"
								type="text"
								bind:value={formData.agentLastName}
								autocomplete="agentLastName"
							/>
							<label
								for="bookingFormField-agentLastName"
								data-tid="elements_bookingForm.form.agentLastName_label"
								>Agent Last Name</label
							>
							<div class="baseline" />
						</div>
					</div>
					<div class="zenya-booking-form-row zenya-booking-row-split">
						<div class="zenya-booking-field">
							<input
								id="bookingFormField-agentPhone"
								data-tid="elements_bookingForm.form.agentPhone_placeholder"
								class="input"
								type="tel"
								bind:value={formData.agentPhone}
								autocomplete="agentPhone"
							/>
							<label
								for="bookingFormField-agentPhone"
								data-tid="elements_bookingForm.form.agentPhone_label"
								>Agent Contact Number</label
							>
							<div class="baseline" />
						</div>
						<div class="zenya-booking-field">
							<input
								id="bookingFormField-agentEmail"
								data-tid="elements_bookingForm.form.agentEmail_placeholder"
								class="input"
								type="email"
								bind:value={formData.agentEmail}
								autocomplete="agentEmail"
							/>
							<label
								for="bookingFormField-agentEmail"
								data-tid="elements_bookingForm.form.agentEmail_label"
								>Agent Email</label
							>
							<div class="baseline" />
						</div>
					</div>
				</div>
			{/if}

			<TotalCalc bind:this={totalExt} />

			{#if $_config.termsConditions}
				<div class="booking-section">
					<div class="form-section">
						<h3>Terms &amp; Conditions</h3>
						<quote class="terms-container">
							{@html $_config.termsConditions.documentHTML}
						</quote>

						<label><input type="checkbox" required />I Agree</label>
					</div>
				</div>
			{/if}

			<div class="error" role="alert"><span class="message" /></div>

			{#if submitErrors}
				{#each submitErrors as submitError}
					<div>{submitError}</div>
				{/each}
			{/if}

			<button
				class="zenya-booking-button zenya-booking-form-submit"
				type="submit"
				data-tid="elements_bookingForm.form.pay_button"
				disabled={$guests.length < 1}>Submit</button
			>
		{/if}
	</form>
</div>

<script>
	import TotalCalc from "./TotalCalc.svelte";

	import { tweened } from "svelte/motion";
	import { onMount, getContext, tick } from "svelte";

	import { route, _loading } from "./RoutingStore";
	import {
		blockedReservation,
		bookingInfo,
		requestInfo,
		guests,
		payors,
		assignees,
		activeFees,
		allFees,
		_paymentFrequency,
		_paymentFrequencyDefault,
		_specialRequests,
	} from "./BookingStore";
	import { _config, _metadata } from "./ConfigStore";
	import { currentBuilding, currentRoom } from "./RoomStore";

	import dayjs from "dayjs";
	import duration from "dayjs/plugin/duration";
	dayjs.extend(duration);
	import currency from "currency.js";

	import BookingGuestsComponent from "./BookingGuestsComponent.svelte";
	import BookingFeeComponent from "./BookingFeeComponent.svelte";

	let bookingGuests;

	let formData = {};
	let specialRequests = "";
	let post = getContext("fetch");

	let submitErrors = [];

	/// COUNTDOWN
	let reservedTime = 900; /// SECONDS
	let timer = tweened(reservedTime);
	$: reservedMinutes = Math.floor($timer / 60);
	$: reservedMinname = reservedMinutes > 1 ? "mins" : "min";
	$: reservedSeconds = Math.floor($timer - reservedMinutes * 60);
	setInterval(() => {
		if ($timer > 0) $timer--;
	}, 1000);

	/// SET UP META DATA FOR DISPLAYING FEES
	let durationType, days, nights, months, timeframe;
	timeframe = $currentRoom.timeframe;
	months = dayjs($requestInfo.endDate)
		.add(1, "day")
		.diff($requestInfo.startDate, "months", true)
		.toFixed(2);

	nights = dayjs($requestInfo.endDate).diff(
		$requestInfo.startDate,
		"days",
		true
	);
	days = nights + 1;
	if (timeframe == "Month") {
		durationType = months;
	} else if (timeframe == "Night") {
		durationType = nights;
	} else if (timeframe == "Day") {
		durationType = days;
	}

	let totalExt;

	/// SET UP RATE/RENT INFO
	// let rate = $currentRoom.rate;
	// let rateTotal = calculateFeeTotal(rate),
	// 	feeTotal = 0;
	// let taxes = [];

	// let subTotal = 0;
	// $: total = currency(subTotal).add(
	// 	taxes.reduce((s, t) => currency(s).add(t.tax), 0)
	// );

	// let refresher = 0;
	// $: refresh = refresher + 1;

	// /// PAYORS + GUESTS
	let paymentFrequency = {};
	// let frequencyAvailable = false;
	// let selectedFrequency = "Full";
	// let assignedPayors = [];

	onMount(async () => {
		_loading.set(true);
		let postResult = await post("bookingMetadata", {
			bookingCode: $requestInfo.bookingCode,
		});
		_loading.set(false);

		if (postResult.ok) {
			let result = await postResult.json();

			paymentFrequency = result.data.paymentFrequency;
		} else {
			console.error(await postResult.json());
		}
	});

	async function submitBooking(e) {
		let tempTotal = totalExt.getTotal();
		let selectedFees = [...$activeFees];

		submitErrors = [];

		let wrongAmount = selectedFees.filter((f) => f.error);
		if (wrongAmount.length > 0) {
			submitErrors = [
				...submitErrors,
				...wrongAmount.map(
					(n) =>
						`Please ensure the amount being paid for ${n.ratetypename} matches the total price`
				),
			];
		}

		if (!$guests || !$guests.length) {
			submitErrors = [...submitErrors, "No guests have been added"];
		}

		if (submitErrors.length < 1) {
			if ($_paymentFrequency != $_paymentFrequencyDefault) {
				selectedFees = selectedFees.map((f) => {
					f.invoiceItemScheduleId =
						paymentFrequency.invoiceitemscheduleId;
					return f;
				});
			}

			_loading.set(true);

			let postData = {
				...formData,
				comment: $_specialRequests,
				bookingCode: $requestInfo.bookingCode,
				guestId: $blockedReservation.guestId,
				roomNumber: $currentRoom.name,
				roomId: $currentRoom.roomId,
				numberNights: nights,
				guests: $assignees,
				payors: $payors,
				rates: selectedFees,
				checkIn: $requestInfo.startDate,
				checkOut: $requestInfo.endDate,
				labels: $blockedReservation.labels,
			};
			let postResult = await post("addReservation", postData);
			_loading.set(false);
			if (postResult.ok) {
				let result = await postResult.json();
				bookingInfo.set({
					...postData,
					...result.data,
					rent: $currentRoom.rate,
					roomType: $currentRoom.roomType,
					timeframe: timeframe,
					duration: durationType,

					buildingName: $currentBuilding.buildingName,
					address: $currentBuilding.address,
					total: tempTotal,
				});
				_loading.set(false);
				if ($_config.agreementDocument) {
					route.set("signagreement");
				} else {
					/// WHAT IS THE PROCESS FOR NO AGREEMENT??
					route.set("confirmation");
				}
			} else {
				let errorRes = await postResult.json();
				submitErrors = [errorRes.data || errorRes.message];
				console.error(errorRes);
			}
		}
	}

	function paidByLabel(o) {
		return (
			o.firstName +
			" " +
			(o.lastName || "") +
			(o.ccType ? " (" + o.ccType + ")" : "")
		);
	}
</script>

<style type="text/scss">
	.zenya-booking-reserved-timer {
		padding: 10px;
		border: 1px solid;
		border-radius: 4px;
		text-align: center;
		// font-weight: bolder;
		position: sticky;
		top: 0;
		z-index: 200;

		&.zenya-panel-info {
			border-color: #00529b;
			color: #00529b;
			background: #bde5f8;
		}
		&.zenya-panel-warning {
			border-color: #9f6000;
			color: #9f6000;
			background: #feefb3;
		}
	}

	.booking-section {
		border: 1px solid darkgray;
		border-radius: 4px;
		margin: 10px 0;
		padding: 0 10px;
	}
	.booking-details-addons {
		background: whitesmoke;
		padding: 1px 10px;
		border-radius: 4px;
		border: 1px solid lightgray;
		margin-bottom: 15px;

		h4 {
			margin: 0.5em 0;
		}

		&.rate > .booking-addon-item {
			padding-left: 0;
			border-bottom: 0;
		}

		.booking-addons-comment {
			margin-bottom: 7px;

			textarea {
				max-width: 100%;
				width: 100%;
			}
		}
	}

	.booking-layout {
		// display: flex;
		// flex-direction: row;
		// flex-wrap: wrap;

		.booking-details {
			// flex: 1;
		}
		.booking-form {
			// flex: 2;

			.form-section {
				margin-bottom: 10px;

				.zenya-form-group {
					display: flex;

					label {
						flex: 1;
						margin: 5px;
					}
				}
			}

			quote.terms-container {
				display: block;
				max-height: 300px;
				overflow-y: auto;
				border: 1px solid lightgray;
				border-radius: 4px;
				padding: 5px;
				background: whitesmoke;
				margin-bottom: 10px;
			}
		}
	}

	.zenya-select-container {
		--height: 24px;
		--clearSelectTop: 3px;
		--clearSelectBottom: 3px;
		--padding: 0 5px;
		font-weight: normal;
		// --inputLeft: 16px;

		display: flex;

		:global(.selectContainer) {
			flex: 1;
		}

		.zenya-circle-button {
			border-radius: 50%;
			height: 24px;
			width: 24px;
			border: 1px solid rgba(0, 0, 0, 0.4);
			/* background: rgba(0, 0, 0, 0.4); */
			margin-left: 7px;
		}
	}

	.zenya-assigned-payors {
		font-size: 0.8em;
		padding: 0.6em 1em;
		border-radius: 4px;
		background: whitesmoke;
		margin-top: 12px;

		.zenya-assigned-payors-title {
			font-size: 1.2em;
			font-weight: bold;
			margin-bottom: 0.5em;
		}

		.zenya-assigned-payor {
			margin-bottom: 0.5em;
			padding-bottom: 0.5em;
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);

			.zenya-assigned-payor-name {
				font-weight: 500;
				font-size: 1.1em;
			}
		}
	}

	.zenya-booking-guest-container {
		padding-bottom: 10px;

		.zenya-booking-guest {
			margin-bottom: 10px;
			padding: 8px;
			background: whitesmoke;
			border-radius: 4px;

			.zenya-booking-guest-actions {
				display: flex;
				flex-direction: row;
			}

			.zenya-booking-form-row {
				margin-bottom: 10px;
			}
		}
	}

	.zenya-booking-form-row {
		display: grid;
		margin: 0 0px 10px;
		column-gap: 12px;
		grid-template-columns: 1fr;

		&.zenya-booking-row-split {
			grid-template-columns: 1fr 1fr;
		}

		&.zenya-booking-row-address {
			grid-template-columns: 3fr 1fr 1fr;
		}

		.zenya-booking-field {
			position: relative;
			width: 100%;
			height: 2.5em;
			padding: 0 2px;
			// margin: 0 10px;

			.baseline {
				position: absolute;
				width: 100%;
				height: 1px;
				left: 0;
				bottom: 0;
				background-color: #cfd7df;
				transition: background-color 0.3s
					cubic-bezier(0.165, 0.84, 0.44, 1);
			}

			label:not(.checkbox) {
				padding: 0 !important;
				margin-bottom: 0;
				z-index: 5;
				background: #fff !important;
				border: 0 !important;
				font-size: 16px !important;
				position: absolute;
				width: 100%;
				left: 0;
				bottom: 1px;
				color: #cfd7df;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				transform-origin: 0 50%;
				cursor: text;
				pointer-events: none;
				transition-property: color, transform;
				transition-duration: 0.3s;
				transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
				transform: scale(0.75) translateY(-1.3em);
			}

			.input:not([type="checkbox"]) {
				position: absolute;
				height: 1.3em !important;
				width: 100%;
				left: 0;
				bottom: 0;
				padding-bottom: 7px;
				color: #32325d;
				background-color: transparent;
			}

			.input::-webkit-input-placeholder {
				color: transparent;
				transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
			}
		}
	}
</style>
