<div class="booking-details booking-section" class:estimator>
	{#if estimator}
		<h2>Estimate Total</h2>
	{:else}
		<!-- SHOW RESERVATION INFO -->
		<h2>Details</h2>
		<div class="booking-detail-item">
			{$_config.checkinAlt}:
			{dayjs($requestInfo.startDate).format("dddd, MMMM D, YYYY")}
		</div>
		<div class="booking-detail-item">
			{$_config.checkoutAlt}:
			{dayjs($requestInfo.endDate).format("dddd, MMMM D, YYYY")}
		</div>
		<div class="booking-detail-item">
			Number of
			{timeframe}s:
			{durationType}
		</div>
		<div class="booking-detail-item">
			Cost per
			{timeframe}:
			{currency($currentRoom.price).format()}
		</div>
	{/if}

	<!-- SHOW RATE/RENT INFORMATION -->
	<div class="booking-details-addons rate">
		<BookingFeeComponent
			fee={$currentRoom.rate}
			{bookingGuests}
			{estimator}
		/>
	</div>

	<!-- LIST ADDITIONAL FEES AND DETAILS -->
	{#if $currentRoom.additionalFees && $currentRoom.additionalFees.length}
		<div class="booking-details-addons">
			<h4>Additional Fees</h4>
			{#each $currentRoom.additionalFees as fee}
				<BookingFeeComponent {fee} {bookingGuests} {estimator} />
			{/each}
			<h4>
				Additional Options Total:
				{currency(additionalOptionsTotal($activeFees)).format()}
			</h4>
			{#if !estimator}
				<div class="booking-addons-comment">
					<div class="zenya-form-group">
						<label>
							Special Requests
							<textarea
								name="comment"
								bind:value={specialRequests}
								placeholder={$_config.specialRequestsPlaceholder}
								on:input={(e) => updateSpecialRequestStore(e)}
							/>
						</label>
					</div>
				</div>
			{/if}
		</div>
	{/if}

	{#if frequencyAvailable}
		<div class="booking-details-addons">
			<h4>Payment Frequency</h4>
			<div>
				<label class="plain"
					><input
						type="radio"
						bind:group={$_paymentFrequency}
						value={$_paymentFrequencyDefault}
					/>{$_paymentFrequencyDefault}</label
				>
			</div>
			<div>
				<label class="plain"
					><input
						type="radio"
						bind:group={$_paymentFrequency}
						value={paymentFrequency.valuename}
					/>{paymentFrequency.valuename}</label
				>
			</div>
		</div>
	{/if}

	<!-- ASSIGNED PAYORS SECTION -->
	{#if assignedPayors && assignedPayors.length}
		<div class="zenya-assigned-payors">
			<div class="zenya-assigned-payors-title">Payor Breakdown</div>
			{#each assignedPayors as p}
				<div class="zenya-assigned-payor">
					<div class="zenya-assigned-payor-name">
						{paidByLabel(p)}
					</div>
					{#each p.payments as c}
						<div>
							{c.ratetypename}: {currency(c.subtotal).format()}
							<em>(+{currency(c.taxTotal).format()} Tax)</em>
						</div>
					{/each}

					<strong
						><em>
							Total: {currency(p.subtotal).format()} (+{currency(
								p.taxTotal
							).format()}
							{#if p.taxAdjusted}
								<span
									aria-label="Adjusted for taxable time period"
									data-microtip-position="right"
									role="tooltip">*</span
								>
							{/if} Tax)
						</em></strong
					>
					<div>
						<em
							>{perPayment(
								currency(p.subtotal).add(p.taxTotal).value,
								$_paymentFrequency
							)}</em
						>
					</div>
				</div>
			{/each}
		</div>
	{/if}

	<!-- TOTALS SECTION -->
	<h3>Subtotal: {currency(subTotal).format()}</h3>
	<div class="details-taxes">
		{#each taxes as tax}
			<div>
				{tax.taxrateTypeName}
				{#if tax.taxratepercentage}
					({tax.taxratepercentage * 100}%)
				{:else if tax.taxratedaily}
					({currency(tax.taxratedaily).format()}/Day)
				{:else if tax.taxratenightly}
					({currency(tax.taxratenightly).format()}/Night)
				{/if}:
				{currency(tax.tax).format()}
				{#if tax.taxAdjusted}
					<span
						aria-label="Adjusted for taxable time period"
						data-microtip-position="right"
						role="tooltip">*</span
					>
				{/if}
			</div>
		{/each}
	</div>

	<h3>Total: {currency(total).format()}</h3>
</div>

<script>
	import { tweened } from "svelte/motion";
	import { onMount, getContext } from "svelte";

	import { route, _loading } from "./RoutingStore";
	import {
		blockedReservation,
		bookingInfo,
		requestInfo,
		guests,
		payors,
		assignees,
		activeFees,
		allFees,
		_paymentFrequency,
		_paymentFrequencyDefault,
		_specialRequests,
	} from "./BookingStore";
	import { _config, _metadata } from "./ConfigStore";
	import { currentBuilding, currentRoom } from "./RoomStore";

	import dayjs from "dayjs";
	import duration from "dayjs/plugin/duration";
	dayjs.extend(duration);
	import currency from "currency.js";

	import BookingFeeComponent from "./BookingFeeComponent.svelte";

	export let estimator = false;

	let bookingGuests;

	let formData = {};
	let specialRequests;
	let post = getContext("fetch");

	let submitErrors = [];

	/// COUNTDOWN
	let reservedTime = 900; /// SECONDS
	let timer = tweened(reservedTime);
	$: reservedMinutes = Math.floor($timer / 60);
	$: reservedMinname = reservedMinutes > 1 ? "mins" : "min";
	$: reservedSeconds = Math.floor($timer - reservedMinutes * 60);
	setInterval(() => {
		if ($timer > 0) $timer--;
	}, 1000);

	/// SET UP META DATA FOR DISPLAYING FEES
	let durationType, days, nights, months, timeframe;
	timeframe = $currentRoom.timeframe;
	months = dayjs($requestInfo.endDate)
		.add(1, "day")
		.diff($requestInfo.startDate, "months", true)
		.toFixed(2);

	nights = dayjs($requestInfo.endDate).diff(
		$requestInfo.startDate,
		"days",
		true
	);
	days = nights + 1;
	if (timeframe == "Month") {
		durationType = months;
	} else if (timeframe == "Night") {
		durationType = nights;
	} else if (timeframe == "Day") {
		durationType = days;
	}

	/// SET UP RATE/RENT INFO
	let rate = $currentRoom.rate;
	let rateTotal = calculateFeeTotal(rate),
		feeTotal = 0;
	let taxes = [];

	let subTotal = 0;
	$: total = currency(subTotal).add(
		taxes.reduce((s, t) => currency(s).add(t.tax), 0)
	);

	export const getTotal = () => {
		return total;
	};

	let refresher = 0;
	$: refresh = refresher + 1;

	/// PAYORS + GUESTS
	let paymentFrequency = {};
	let paymentFrequencyDefault = {};
	let frequencyAvailable = false;
	// let selectedFrequency = "Full";
	let assignedPayors = [];

	calculateFees();
	allFees.set([$currentRoom.rate, ...$currentRoom.additionalFees]);

	onMount(async () => {
		_specialRequests.set("");
		_loading.set(true);
		let postResult = await post("bookingMetadata", {
			bookingCode: $requestInfo.bookingCode,
		});
		_loading.set(false);

		activeFees.subscribe(() => assignPayments());

		if (postResult.ok) {
			let result = await postResult.json();

			_metadata.set(result.data);

			/// ADD TO STORE
			// if (
			// 	result.data &&
			// 	result.data.companyContacts &&
			// 	result.data.companyContacts.length
			// ) {
			// 	result.data.companyContacts.forEach((c) => {
			// 		guests.saveGuest({
			// 			...c,
			// 			type: "payor",
			// 		});
			// 	});
			// }

			if (result.data.paymentFrequencyOverride) {
				paymentFrequencyDefault = result.data.paymentFrequencyOverride;
				$_paymentFrequencyDefault = paymentFrequencyDefault.valuename;
				$_paymentFrequency = paymentFrequencyDefault.valuename;
			} else {
				paymentFrequencyDefault = {
					valuename: "Full",
					invoiceitemschedulefrequencyId: 5,
				};
				$_paymentFrequencyDefault = "Full";
				$_paymentFrequency = "Full";
			}

			paymentFrequency = result.data.paymentFrequency;
			if (paymentFrequency && paymentFrequency.invoiceitemscheduleId) {
				frequencyAvailability();
				if (
					frequencyAvailable &&
					$_config.companyFrequencyDefault == "Enabled"
				) {
					$_paymentFrequency = paymentFrequency.valuename;
				}
			}
		} else {
			console.error(await postResult.json());
		}
	});

	function calculateFees(skipPayments) {
		$currentRoom.rate = {
			...$currentRoom.rate,
			subtotal: calculateFeeTotal($currentRoom.rate),
			filteredAssignees: [],
			filteredPayors: [],
		};
		taxForItem($currentRoom.rate);

		// $currentRoom.additionalFees = [...$currentRoom.additionalFees];

		let tempFeeTotal = $currentRoom.additionalFees.reduce((t, n) => {
			n.subtotal = calculateFeeTotal(n);
			n.filteredAssignees = [];
			n.filteredPayors = [];
			if (n.checked || !n.optional) {
				return currency(t).add(n.subtotal);
			} else {
				return t;
			}
		}, 0);
		$currentRoom.additionalFees =
			$currentRoom.additionalFees.map(taxForItem);
		let convertedFeeTotal = currency(tempFeeTotal);
		feeTotal = convertedFeeTotal;
		// calculateTaxes(currency(rateTotal).add(feeTotal));
		// assignPayments();
	}

	function calculateFeeTotal(_fee) {
		let feeTotal = 0;
		if (_fee.pricetypename == "Full") {
			feeTotal = currency(_fee.price);
		} else if (_fee.pricetypename == "Monthly") {
			feeTotal = currency(_fee.price).multiply(months);
		} else if (_fee.pricetypename == "Daily") {
			feeTotal = currency(_fee.price).multiply(days);
		} else if (_fee.pricetypename == "Nightly") {
			feeTotal = currency(_fee.price).multiply(nights);
		}
		return feeTotal;
	}
	function taxForItem(item) {
		if (
			item.taxes &&
			item.taxes.length &&
			$currentRoom.taxes &&
			$currentRoom.taxes.length
		) {
			item.taxes = item.taxes.map((t) => {
				/// MATCH ITEM TAX TO ROOM/LOCATION TAXES
				let roomTax = $currentRoom.taxes.find(
					(rt) => rt.taxratetypeId == t.taxratetypeId
				);
				t.amount = 0;
				if (roomTax) {
					/// IF MATCHED, CALCULATE TAX AMOUNT
					if (roomTax.taxratepercentage) {
						if (
							(roomTax.taxable && roomTax.taxable.days) ||
							(roomTax.nonTaxable && roomTax.nonTaxable.days)
						) {
							/// GET RATIO OF TAXABLE TIME vs TOTAL TIME
							let taxableDays =
								roomTax.taxable && roomTax.taxable.days
									? roomTax.taxable.days
									: 0;
							let nonTaxableDays =
								roomTax.nonTaxable && roomTax.nonTaxable.days
									? roomTax.nonTaxable.days
									: 0;

							let ratio =
								taxableDays / (nonTaxableDays + taxableDays);
							if (ratio != 1) {
								t.amount = currency(item.subtotal).multiply(
									roomTax.taxratepercentage * ratio
								);
								t.adjusted = true;
								item.taxAdjusted = true;
							} else {
								t.amount = currency(item.subtotal).multiply(
									roomTax.taxratepercentage
								);
							}
						} else {
							t.amount = currency(item.subtotal).multiply(
								roomTax.taxratepercentage
							);
						}
					} else if (roomTax.taxratenightly) {
						let taxableNights;
						if (roomTax.taxable && roomTax.taxable.days) {
							if (roomTax.taxable && roomTax.taxable.days) {
								taxableNights = roomTax.taxable.days - 1;
								t.adjusted = true;
								item.taxAdjusted = true;
							} else {
								taxableNights = 0;
							}
						} else {
							taxableNights = nights;
						}

						t.amount = currency(roomTax.taxratenightly).multiply(
							taxableNights
						);
					} else if (roomTax.taxratedaily) {
						let taxableDays;
						if (roomTax.taxable && roomTax.taxable.days) {
							if (roomTax.taxable && roomTax.taxable.days) {
								taxableDays = roomTax.taxable.days;
								t.adjusted = true;
								item.taxAdjusted = true;
							} else {
								taxableDays = 0;
							}
						} else {
							taxableDays = days;
						}

						t.amount = currency(roomTax.taxratedaily).multiply(
							taxableDays
						);
					}
				}
				return t;
			});

			/// ADD AMOUNTS FOR EACH TAX TYPE TO GET TAX TOTAL
			item.taxTotal = item.taxes.reduce(
				(s, n) => currency(s).add(n.amount),
				0
			);

			/// ADD SUBTOTAL TO TAX TOTAL TO GET FINAL ITEM TOTAL
			item.total = currency(item.subtotal).add(item.taxTotal);
		} else {
			item.taxTotal = 0;
		}
		return item;
	}

	function frequencyAvailability() {
		let requestDuration = dayjs.duration(days, "days");
		let frequencyDuration;
		if (paymentFrequency.invoiceitemschedulefrequencyId == 1) {
			frequencyDuration = dayjs.duration(1, "day");
		} else if (paymentFrequency.invoiceitemschedulefrequencyId == 2) {
			frequencyDuration = dayjs.duration(1, "week");
		} else if (paymentFrequency.invoiceitemschedulefrequencyId == 3) {
			frequencyDuration = dayjs.duration(2, "weeks");
		} else if (paymentFrequency.invoiceitemschedulefrequencyId == 4) {
			frequencyDuration = dayjs.duration(1, "month");
		} else if (paymentFrequency.invoiceitemschedulefrequencyId == 5) {
			// frequencyDuration = dayjs.duration( 1, '');
		} else if (paymentFrequency.invoiceitemschedulefrequencyId == 6) {
			if (paymentFrequency.refinvoiceitemschedulefrequencytypeId == 1) {
				frequencyDuration = dayjs.duration(
					paymentFrequency.custominvoiceitemschedulefrequency,
					"days"
				);
			} else if (
				paymentFrequency.refinvoiceitemschedulefrequencytypeId == 2
			) {
				frequencyDuration = dayjs.duration(
					paymentFrequency.custominvoiceitemschedulefrequency,
					"weeks"
				);
			} else if (
				paymentFrequency.refinvoiceitemschedulefrequencytypeId == 3
			) {
				frequencyDuration = dayjs.duration(
					paymentFrequency.custominvoiceitemschedulefrequency,
					"months"
				);
			}
		}

		if (
			frequencyDuration &&
			frequencyDuration.asDays() < requestDuration.asDays()
		) {
			frequencyAvailable = true;
		}
	}

	function getPayorRate(payorId, rate) {
		/// EXTRACT PAYOR INFORMATION FROM A RATE

		if (rate.assignees && rate.assignees.length) {
			/// GET PAYOR OBJECT FOR EACH ASSIGNEE
			let assignedToPayor = rate.assignees
				.map((a) => a.payors.find((p) => p.payorId == payorId))
				.filter((a) => a);

			if (assignedToPayor && assignedToPayor.length) {
				/// ADD PERCENT APPLIED ACROSS PAYOR OBJECTS
				let payorPercent = assignedToPayor.reduce(
					(t, a) => t + a.percent[0],
					0
				);

				/// TOTAL IS THE FEE TIMES THE PERCENT
				let payorTotal = currency(calculateFeeTotal(rate)).multiply(
					payorPercent / 100
				);

				let copy = {
					...rate,
					subtotal: currency(payorTotal),
				};

				/// CALCULATE TAXES AND FINAL ITEM TOTAL
				return { ...taxForItem(copy) };
			}
		}
		return {};
	}

	function assignPayments() {
		assignedPayors = $payors
			.map((payor) => {
				let copy = { ...payor };

				/// GET COPY OF FEES ASSIGNED TO PAYOR
				copy.payments = $activeFees
					.map((f) => getPayorRate(copy.payorId, f))
					.filter((f) => f.subtotal && f.subtotal.value);

				copy.subtotal = 0;
				copy.total = 0;
				copy.taxTotal = 0;
				copy.payments = copy.payments.map((p) => {
					p = { ...p };

					/// ADD PAYMENT TOTALS TO PAYOR TOTALS
					copy.subtotal = currency(copy.subtotal).add(p.subtotal);
					copy.taxTotal = currency(copy.taxTotal).add(p.taxTotal);
					copy.total = currency(copy.total).add(p.total);
					return p;
				});

				return copy;
			})
			.filter((p) => p.payments && p.payments.length);

		calculateTotals();
	}

	function calculateTotals() {
		/// COPY ACTIVE FEES
		let allFees = [...$activeFees];

		/// CALCULATE TAXES
		let taxArray = $currentRoom.taxes || [];
		taxes = taxArray.map((t) => {
			t.tax = allFees
				.map((f) => {
					if (f && f.taxes && f.taxes.length) {
						let matchedTax = f.taxes.find(
							(ft) => t.taxratetypeId == ft.taxratetypeId
						);
						if (f.taxAdjusted) {
							t.taxAdjusted = true;
						}
						return matchedTax ? matchedTax.amount : 0;
					} else {
						return 0;
					}
				})
				.reduce((t, n) => currency(t).add(n), 0);
			return t;
		});

		/// CALCULATE SUBTOTAL -> TOTAL IS DYNAMIC BASED ON THIS AND TAXES
		subTotal = allFees.reduce((t, n) => currency(t).add(n.subtotal), 0);
	}

	function additionalOptionsTotal() {
		let addFees = $activeFees.filter((f) => f.ratetypeId != 1);

		return addFees.reduce((t, f) => currency(t).add(f.subtotal), 0);
	}

	function perPayment(payAmount) {
		/*
			CALCULATE HOW MUCH WILL BE PAID PER SELECTED PAY PERIOD
		*/
		let fAmount = 0;
		let fTimeCount = "";
		let fTimeType = "";

		let selectedFrequency =
			paymentFrequencyDefault &&
			paymentFrequencyDefault.valuename == $_paymentFrequency
				? paymentFrequencyDefault
				: paymentFrequency;

		let weeks = dayjs($requestInfo.endDate)
			.add(1, "day")
			.diff($requestInfo.startDate, "weeks", true)
			.toFixed(2);
		if (selectedFrequency.invoiceitemschedulefrequencyId == 1) {
			fAmount = currency(payAmount).value / days;
			fTimeType = "day";
		} else if (selectedFrequency.invoiceitemschedulefrequencyId == 2) {
			fAmount = currency(payAmount).value / weeks;
			fTimeType = "week";
		} else if (selectedFrequency.invoiceitemschedulefrequencyId == 3) {
			fAmount = currency(payAmount).value / (weeks / 2);
			fTimeCount = 2;
			fTimeType = "weeks";
		} else if (selectedFrequency.invoiceitemschedulefrequencyId == 4) {
			fAmount = currency(payAmount).value / months;
			fTimeType = "month";
		} else if (selectedFrequency.invoiceitemschedulefrequencyId == 5) {
			fAmount = currency(payAmount).value;
			fTimeType = "";
		} else if (selectedFrequency.invoiceitemschedulefrequencyId == 6) {
			/// CALCULATE CUSTOM AMOUNTS
			let divisor = 1;
			if (selectedFrequency.refinvoiceitemschedulefrequencytypeId == 1) {
				divisor =
					days / selectedFrequency.custominvoiceitemschedulefrequency;

				fTimeType = "days";
			} else if (
				selectedFrequency.refinvoiceitemschedulefrequencytypeId == 2
			) {
				divisor =
					weeks /
					selectedFrequency.custominvoiceitemschedulefrequency;
				fTimeType = "weeks";
			} else if (
				selectedFrequency.refinvoiceitemschedulefrequencytypeId == 3
			) {
				divisor =
					months /
					selectedFrequency.custominvoiceitemschedulefrequency;
				fTimeType = "months";
			}
			fTimeCount = selectedFrequency.custominvoiceitemschedulefrequency;
			fAmount = currency(payAmount).value / divisor;
		}
		console.log(paymentFrequencyDefault.valuename, $_paymentFrequency);
		console.log(
			selectedFrequency.invoiceitemschedulefrequencyId,
			fAmount,
			fTimeCount,
			fTimeType
		);
		return fAmount && fTimeType
			? `(${currency(fAmount).format()} per ${fTimeCount} ${fTimeType})`
			: "";
	}
	/*
	async function submitBooking(e) {
		let selectedFees = [...$activeFees];

		submitErrors = [];

		let wrongAmount = selectedFees.filter((f) => f.error);
		if (wrongAmount.length > 0) {
			submitErrors = [
				...submitErrors,
				...wrongAmount.map(
					(n) =>
						`Please ensure the amount being paid for ${n.ratetypename} matches the total price`
				),
			];
		}

		if (!$guests || !$guests.length) {
			submitErrors = [...submitErrors, "No guests have been added"];
		}

		if (submitErrors.length < 1) {
			if ($_paymentFrequency != "Full") {
				selectedFees = selectedFees.map((f) => {
					f.invoiceItemScheduleId =
						paymentFrequency.invoiceitemscheduleId;
					return f;
				});
			}

			_loading.set(true);

			let postData = {
				...formData,
				bookingCode: $requestInfo.bookingCode,
				guestId: $blockedReservation.guestId,
				roomNumber: $currentRoom.name,
				roomId: $currentRoom.roomId,
				numberNights: nights,
				guests: $assignees,
				payors: $payors,
				rates: selectedFees,
				checkIn: $requestInfo.startDate,
				checkOut: $requestInfo.endDate,
				labels: $blockedReservation.labels,
			};
			let postResult = await post("addReservation", postData);
			if (postResult.ok) {
				let result = await postResult.json();
				bookingInfo.set({
					...postData,
					...result.data,
					rent: $currentRoom.rate,
					roomType: $currentRoom.roomType,
					timeframe: timeframe,
					duration: durationType,

					buildingName: $currentBuilding.buildingName,
					address: $currentBuilding.address,
					total: total,
				});
				_loading.set(false);
				if ($_config.agreementDocument) {
					route.set("signagreement");
				} else {
					/// WHAT IS THE PROCESS FOR NO AGREEMENT??
					route.set("confirmation");
				}
			} else {
				console.error(await postResult.json());
			}
		}
	}
*/
	function paidByLabel(o) {
		return (
			o.firstName +
			" " +
			(o.lastName || "") +
			(o.ccType ? " (" + o.ccType + ")" : "")
		);
	}

	function updateSpecialRequestStore(e) {
		console.log(e);
		_specialRequests.set(e.target.value);
	}
</script>

<style type="text/scss">
	/*
	.zenya-booking-reserved-timer {
		padding: 10px;
		border: 1px solid;
		border-radius: 4px;
		text-align: center;
		// font-weight: bolder;
		position: sticky;
		top: 0;
		z-index: 200;

		&.zenya-panel-info {
			border-color: #00529b;
			color: #00529b;
			background: #bde5f8;
		}
		&.zenya-panel-warning {
			border-color: #9f6000;
			color: #9f6000;
			background: #feefb3;
		}
	}
*/
	.booking-section {
		border: 1px solid darkgray;
		border-radius: 4px;
		margin: 10px 0;
		padding: 0 10px;

		&.estimator {
			border: 0;
			border-top: 1px solid lightgray;
			border-radius: 0;
		}
	}
	.booking-details-addons {
		background: whitesmoke;
		padding: 1px 10px;
		border-radius: 4px;
		border: 1px solid lightgray;
		margin-bottom: 15px;

		h4 {
			margin: 0.5em 0;
		}

		&.rate > .booking-addon-item {
			padding-left: 0;
			border-bottom: 0;
		}

		.booking-addons-comment {
			margin-bottom: 7px;

			textarea {
				max-width: 100%;
				width: 100%;
			}
		}
	}

	/*
	.booking-layout {
		// display: flex;
		// flex-direction: row;
		// flex-wrap: wrap;

		.booking-details {
			// flex: 1;
		}
		.booking-form {
			// flex: 2;

			.form-section {
				margin-bottom: 10px;

				.zenya-form-group {
					display: flex;

					label {
						flex: 1;
						margin: 5px;
					}
				}
			}

			quote.terms-container {
				display: block;
				max-height: 300px;
				overflow-y: auto;
				border: 1px solid lightgray;
				border-radius: 4px;
				padding: 5px;
				background: whitesmoke;
				margin-bottom: 10px;
			}
		}
	}
	.zenya-select-container {
		--height: 24px;
		--clearSelectTop: 3px;
		--clearSelectBottom: 3px;
		--padding: 0 5px;
		font-weight: normal;
		// --inputLeft: 16px;

		display: flex;

		:global(.selectContainer) {
			flex: 1;
		}

		.zenya-circle-button {
			border-radius: 50%;
			height: 24px;
			width: 24px;
			border: 1px solid rgba(0, 0, 0, 0.4);
			margin-left: 7px;
		}
	}
*/
	.zenya-assigned-payors {
		font-size: 0.8em;
		padding: 0.6em 1em;
		border-radius: 4px;
		background: whitesmoke;
		margin-top: 12px;

		.zenya-assigned-payors-title {
			font-size: 1.2em;
			font-weight: bold;
			margin-bottom: 0.5em;
		}

		.zenya-assigned-payor {
			margin-bottom: 0.5em;
			padding-bottom: 0.5em;
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);

			.zenya-assigned-payor-name {
				font-weight: 500;
				font-size: 1.1em;
			}
		}
	}

	/*
	.zenya-booking-guest-container {
		padding-bottom: 10px;

		.zenya-booking-guest {
			margin-bottom: 10px;
			padding: 8px;
			background: whitesmoke;
			border-radius: 4px;

			.zenya-booking-guest-actions {
				display: flex;
				flex-direction: row;
			}

			.zenya-booking-form-row {
				margin-bottom: 10px;
			}
		}
	}
	.zenya-booking-form-row {
		display: grid;
		margin: 0 0px 10px;
		column-gap: 12px;
		grid-template-columns: 1fr;

		&.zenya-booking-row-split {
			grid-template-columns: 1fr 1fr;
		}

		&.zenya-booking-row-address {
			grid-template-columns: 3fr 1fr 1fr;
		}

		.zenya-booking-field {
			position: relative;
			width: 100%;
			height: 2.5em;
			padding: 0 2px;
			// margin: 0 10px;

			.baseline {
				position: absolute;
				width: 100%;
				height: 1px;
				left: 0;
				bottom: 0;
				background-color: #cfd7df;
				transition: background-color 0.3s
					cubic-bezier(0.165, 0.84, 0.44, 1);
			}

			label:not(.checkbox) {
				padding: 0 !important;
				margin-bottom: 0;
				z-index: 5;
				background: #fff !important;
				border: 0 !important;
				font-size: 16px !important;
				position: absolute;
				width: 100%;
				left: 0;
				bottom: 1px;
				color: #cfd7df;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				transform-origin: 0 50%;
				cursor: text;
				pointer-events: none;
				transition-property: color, transform;
				transition-duration: 0.3s;
				transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
				transform: scale(0.75) translateY(-1.3em);
			}

			.input:not([type="checkbox"]) {
				position: absolute;
				height: 1.3em !important;
				width: 100%;
				left: 0;
				bottom: 0;
				padding-bottom: 7px;
				color: #32325d;
				background-color: transparent;
			}

			.input::-webkit-input-placeholder {
				color: transparent;
				transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
			}
		}
	}

	*/
</style>
