<div class="zenya-contact-modal-wrapper" transition:fade>
	<div class="zenya-contact-modal-container">
		<div class="zenya-contact-modal-content">
			<div class="booking-form booking-section">
				<div class="bookingForm bookingFormField">
					<form
						bind:this={guestForm}
						class="payment-form"
						on:submit|preventDefault={addGuest}
					>
						{#if $guests.length > 0 && !guest && !newGuest.presetType}
							<div class="zenya-guest-type-container">
								<p class="zenya-guest-type-instructions">
									If a guest will not be making payments or
									payments will be made by a non-guest, chose
									the appropriate option below
								</p>
								<div
									class="zenya-booking-form-row zenya-booking-row-split"
								>
									<div class="zenya-booking-field checkbox">
										{#if !newGuest.onlyPayor}
											<label class="checkbox">
												<input
													type="checkbox"
													bind:checked={newGuest.onlyGuest}
												/> Non-paying Guest
											</label>
										{/if}
									</div>
									<div class="zenya-booking-field checkbox">
										{#if !newGuest.onlyGuest}
											<label class="checkbox">
												<input
													type="checkbox"
													bind:checked={newGuest.onlyPayor}
												/> Paying non-guest
											</label>
										{/if}
									</div>
								</div>
							</div>
						{/if}
						<div
							class="zenya-booking-form-row zenya-booking-row-split"
						>
							<div class="zenya-booking-field">
								<label
									for="bookingFormField-firstName"
									data-tid="elements_bookingForm.form.firstName_label"
								>
									Guest First Name
								</label>
								<input
									id="bookingFormField-firstName"
									data-tid="elements_bookingForm.form.firstName_placeholder"
									class="input"
									type="text"
									bind:value={newGuest.firstName}
									required
									autocomplete="firstName"
								/>
								<div class="baseline" />
							</div>
							<div class="zenya-booking-field">
								<label
									for="bookingFormField-lastName"
									data-tid="elements_bookingForm.form.lastName_label"
									>Guest Last Name</label
								>
								<input
									id="bookingFormField-lastName"
									data-tid="elements_bookingForm.form.lastName_placeholder"
									class="input"
									type="text"
									bind:value={newGuest.lastName}
									required
									autocomplete="lastName"
								/>
								<div class="baseline" />
							</div>
						</div>
						<div
							class="zenya-booking-form-row zenya-booking-row-split"
						>
							<div class="zenya-booking-field">
								<label
									for="bookingFormField-phone"
									data-tid="elements_bookingForm.form.phone_label"
									>Guest Contact Number</label
								>
								<input
									id="bookingFormField-phone"
									data-tid="elements_bookingForm.form.phone_placeholder"
									class="input"
									type="tel"
									bind:value={newGuest.phone}
									required
									autocomplete="phone"
								/>
								<div class="baseline" />
							</div>
							<div class="zenya-booking-field">
								<label
									for="bookingFormField-email"
									data-tid="elements_bookingForm.form.email_label"
									>Guest Email</label
								>
								<input
									id="bookingFormField-email"
									data-tid="elements_bookingForm.form.email_placeholder"
									class="input"
									type="email"
									bind:value={newGuest.email}
									required
									autocomplete="email"
								/>
								<div class="baseline" />
							</div>
						</div>
						{#if $guests.length > 0 && $_config.stripePublicKey && $_metadata.showCC && !newGuest.noCreditCard}
							<div
								class="zenya-booking-form-row zenya-booking-row-split"
							>
								<div class="zenya-booking-field" />
								<div class="zenya-booking-field">
									<small
										>Please add a unique email address for
										each Credit Card</small
									>
								</div>
							</div>
						{/if}

						{#if newGuest.labels && newGuest.labels.length && !newGuest.onlyPayor}
							<div
								class="zenya-booking-form-row zenya-booking-form-grid"
							>
								{#each newGuest.labels as label}
									<div class="zenya-booking-field">
										<label>{label.tagName}</label>
										<input
											class="input"
											type="text"
											bind:value={label.tagvalue}
										/>
										<div class="baseline" />
									</div>
								{/each}
							</div>
						{/if}

						{#if $_config.stripePublicKey && $_metadata.showCC}
							<div
								class="zenya-booking-form-row zenya-booking-row-split"
							>
								<div class="zenya-booking-field checkbox">
									<label class="checkbox">
										<input
											type="checkbox"
											bind:checked={newGuest.noCreditCard}
											on:change={() => reinitCC()}
										/>Send CC Authorization Form
									</label>
								</div>
							</div>
							{#if !newGuest.noCreditCard}
								<div
									class="zenya-booking-form-row zenya-booking-row-split"
								>
									<div class="zenya-booking-field checkbox">
										<label class="checkbox">
											<input
												type="radio"
												bind:group={newGuest.ccType}
												value="Business"
											/>Business
										</label>
									</div>
									<div class="zenya-booking-field checkbox">
										<label class="checkbox">
											<input
												type="radio"
												bind:group={newGuest.ccType}
												value="Personal"
											/>Personal
										</label>
									</div>
								</div>
								<div class:hidden={newGuest.onlyGuest}>
									<div class="zenya-booking-form-row">
										<div class="zenya-booking-field">
											<label
												for="bookingFormField-address"
												data-tid="elements_bookingForm.form.address_label"
												>Billing Address</label
											>
											<input
												id="bookingFormField-address"
												data-tid="elements_bookingForm.form.address_placeholder"
												class="input"
												type="text"
												bind:value={newGuest.address}
												required={!newGuest.onlyGuest}
												autocomplete="address-line1"
											/>
											<div class="baseline" />
										</div>
									</div>

									<div
										class="zenya-booking-form-row zenya-booking-row-address"
									>
										<div class="zenya-booking-field">
											<label
												for="bookingFormField-city"
												data-tid="elements_bookingForm.form.city_label"
												>City</label
											>
											<input
												id="bookingFormField-city"
												data-tid="elements_bookingForm.form.city_placeholder"
												class="input"
												type="text"
												bind:value={newGuest.city}
												required={!newGuest.onlyGuest}
												autocomplete="address-level2"
											/>
											<div class="baseline" />
										</div>
										<div class="zenya-booking-field">
											<label
												for="bookingFormField-state"
												data-tid="elements_bookingForm.form.state_label"
												>State</label
											>
											<input
												id="bookingFormField-state"
												data-tid="elements_bookingForm.form.state_placeholder"
												class="input"
												type="text"
												bind:value={newGuest.state}
												required={!newGuest.onlyGuest}
												autocomplete="address-level1"
											/>
											<div class="baseline" />
										</div>
										<div class="zenya-booking-field">
											<label
												for="bookingFormField-zip"
												data-tid="elements_bookingForm.form.postal_code_label"
												>ZIP</label
											>
											<input
												id="bookingFormField-zip"
												data-tid="elements_bookingForm.form.postal_code_placeholder"
												class="input"
												type="text"
												bind:value={newGuest.zip}
												required={!newGuest.onlyGuest}
												autocomplete="postal-code"
											/>
											<div class="baseline" />
										</div>
									</div>

									{#if newGuest && newGuest.stripeTokenId}
										<div class="zenya-cc-note">
											For security purposes, payment
											information cannot be edited. You
											can override the previously entered
											card by filliing out the fields
											below, otherwise leave them blank.
										</div>
									{:else if $_config.ccFieldNote}
										<div class="zenya-cc-note">
											{$_config.ccFieldNote}
										</div>
									{/if}
									<div class="zenya-booking-form-row">
										<div class="zenya-booking-field">
											<label
												for="zenya-booking-field"
												data-tid="elements_bookingForm.form.card_label"
												>Card</label
											>
											<input
												class="input focused"
												style="display: none;"
											/>
											<div id="zenya-booking-card" />
											<div class="baseline" />
										</div>
									</div>
								</div>
							{/if}
						{/if}

						{#if $_config.backgroundCheck == "Enabled" && !$currentBuilding.buildingDisableBackgroundCheck && newGuest.firstName && newGuest.lastName && newGuest.email}
							<div class="zenya-background-check">
								A background check is required to complete this
								reservation.
								<label class="checkbox">
									<input
										type="checkbox"
										bind:checked={newGuest.acceptBackgroundCheck}
										value="Business"
									/> I understand and give authorization to perform
									a background check
								</label>

								{#if newGuest.acceptBackgroundCheck}
									<div class="zenya-bgcheck-start">
										<!-- {#if $guests.length > 0} -->
										<label class="checkbox">
											<input
												type="checkbox"
												bind:checked={newGuest.emailBGcheck}
												value="sendEmail"
											/> Send link via email
										</label>
										<!-- {/if} -->
										{#if !newGuest.emailBGcheck}
											<button
												type="button"
												class="zenya-booking-button zenya-background-check-start-button"
												on:click|preventDefault={() =>
													beginBackgroundCheck()}
											>
												Start
											</button>
										{/if}
									</div>
								{/if}

								{#if backgroundCheckURL}
									<iframe
										class="zenya-background-check-iframe"
										src={backgroundCheckURL}
										frameborder="0"
									/>
								{/if}
							</div>
						{/if}
						{#if guestFormError}
							<div class="error" role="alert">
								<span class="message">{guestFormError}</span>
							</div>
						{/if}
						<div class="zenya-form-buttons">
							<button
								type="submit"
								class="zenya-booking-button zenya-guest-popup-submit"
								>SUBMIT</button
							>
							<div style="flex:1;" />
							<button
								type="button"
								on:click={cancel}
								class="zenya-base-button zenya-booking-button zenya-guest-popup-close"
								>CLOSE</button
							>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>

<script>
	import { createEventDispatcher, onMount, getContext } from "svelte";
	import { fade } from "svelte/transition";
	import { _config, _metadata } from "./ConfigStore";
	import { guests, blockedReservation } from "./BookingStore";
	import { _loading } from "./RoutingStore";
	import { currentBuilding, currentRoom } from "./RoomStore";

	let post = getContext("fetch");

	const dispatch = createEventDispatcher();

	export let guest = null;
	// export let guestCount = 0;

	let newGuest = {
		labels:
			$_config.assigneeFields && $_config.assigneeFields.length
				? $_config.assigneeFields.map((l) => ({ ...l }))
				: [],
	};
	let guestForm;
	let guestFormError;

	let stripeObj;
	let stripeCard;

	let backgroundCheckURL;

	onMount(() => {
		if (guest) {
			newGuest = { ...guest };
		}
		loadStripeElements();
	});

	function reinitCC() {
		setTimeout(() => {
			if (!newGuest.noCreditCard) {
				loadStripeElements();
			}
		}, 100);
	}

	function loadStripeElements() {
		if ($_config.stripePublicKey && $_metadata.showCC) {
			stripeObj = Stripe($_config.stripePublicKey);
			let elements = stripeObj.elements({
				fonts: [
					{
						cssSrc: "https://fonts.googleapis.com/css?family=Source+Code+Pro",
					},
				],
			});

			let elementStyles = {
				base: {
					"::placeholder": {
						color: "#CFD7DF",
					},
					":-webkit-autofill": {
						color: "#e39f48",
					},
				},
				invalid: {
					color: "#E25950",

					"::placeholder": {
						color: "#FFCCA5",
					},
				},
			};

			let elementClasses = {
				focus: "focused",
				empty: "empty",
				invalid: "invalid",
			};

			var card = elements.create("card", {
				style: elementStyles,
				classes: elementClasses,
				hidePostalCode: true,
			});
			card.mount("#zenya-booking-card");
			card.on("change", ({ error, complete }) => {
				if (error && error.message) {
					/// TODO: DISABLE SUBMIT BUTTON
					console.warn(error);
					guestFormError = error.message;
				} else if (complete) {
					/// TODO: ENABLE SUBMIT BUTTON
					guestFormError = null;
				} else {
					guestFormError = null;
				}
			});

			stripeCard = card;
		} else {
			console.warn("INVALID STRIPE INFORMATION");
		}
	}

	const cancel = () => {
		dispatch("close");
	};

	const addGuest = async () => {
		let bgcCheck = true;
		if (
			$_config.backgroundCheck == "Enabled" &&
			!$currentBuilding.buildingDisableBackgroundCheck
		) {
			bgcCheck = await checkBgcApplication();
		}

		if (
			newGuest.firstName &&
			newGuest.lastName &&
			newGuest.email &&
			newGuest.phone &&
			bgcCheck
		) {
			if (
				!newGuest.onlyGuest &&
				!newGuest.stripeTokenId &&
				$_metadata.showCC &&
				!newGuest.noCreditCard
			) {
				let result = await stripeObj.createSource(stripeCard);
				if (result.source) {
					newGuest = {
						payorId: "n" + Math.floor(Math.random() * Date.now()),
						assigneeId:
							"n" + Math.floor(Math.random() * Date.now()),
						...newGuest,
						stripeTokenId: result.source.id,
						type: !newGuest.onlyPayor ? "both" : "payor",
					};
					confirmed();
				} else {
					guestFormError = "Invalid Credit Card information";
					console.error(result);
				}
			} else {
				if (!newGuest.onlyGuest) {
					newGuest = {
						assigneeId:
							"n" + Math.floor(Math.random() * Date.now()),
						payorId: "n" + Math.floor(Math.random() * Date.now()),
						...newGuest,
						type: !newGuest.onlyPayor ? "both" : "payor",
					};
				} else {
					newGuest = {
						assigneeId:
							"n" + Math.floor(Math.random() * Date.now()),
						...newGuest,
						type: "guest",
					};
				}
				confirmed();
			}
		} else {
			if (bgcCheck) {
				console.error("NOT ALL FIELDS FILLED");
				guestFormError = "Please fill out all required fields";
			} else {
				console.error("BACKGROUND CHECK NOT SUBMITTED");
				guestFormError =
					"Please complete the background check application. It can sometimes take a few seconds to update the status - if you already completed the application and are seeing this, please try again!";
			}
		}
	};

	async function checkBgcApplication() {
		if (newGuest.emailBGcheck) {
			return true;
		} else {
			/// THIS NEEDS TO BE UPDATED WITH A WAY TO CHECK THAT THE
			/// BG CHECK APPLICATIONN HAS BEEN FULLY COMPLETED
			_loading.set(true);
			let postResult = await post("getApplicationStatuses", {
				aigKey: newGuest.backgroundCheckId,
			});
			_loading.set(false);
			let result = await postResult.json();

			if (postResult.ok) {
				if (
					result.data.status == "Submitted" ||
					result.data.status == "Complete"
				) {
					/// BACKGROUND CHECK IS SUBMITTED
					return true;
				} else {
					return false;
				}
			} else {
				if (result.error == "No status given for the application") {
					/// BACKGROUND CHECK MAY BE SUBMITTED, BUT AIG HAS NO RETURNED ANY STATUS
					/// CONTINUE ANYWAY, AND DEAL WITH THE STATUS LATER
					return true;
				} else {
					console.error(result);
					return false;
				}
			}
		}
	}

	function confirmed() {
		guests.saveGuest(newGuest);
		cancel();

		document.querySelector("#zenya-scroll-target").scrollIntoView({
			block: "start",
			inline: "nearest",
			behavior: "smooth",
		});
	}

	async function beginBackgroundCheck() {
		// let response = await fetch(
		// 	"https://application.accurateinformationgroup.com/API/V1/23/BDS",
		// 	{
		// 		method: "POST",
		// 		credentials: "omit", // include, *same-origin, omit
		// 		headers: new Headers(),
		// 		body: JSON.stringify(),
		// 	}
		// );
		// if (response.ok) {
		// 	let result = await response.json();
		// 	backgroundCheckURL = result.applicationUrl;
		// 	newGuest.backgroundCheckId = result.applicationKey;
		// }

		_loading.set(true);
		let postResult = await post("startApplication", {
			customerguestinstanceId: $blockedReservation.guestId,
			firstName: newGuest.firstName,
			lastName: newGuest.lastName,
			email: newGuest.email,
			backgroundcheckgroupId: $blockedReservation.backgroundcheckgroupId,
		});
		_loading.set(false);
		let result = await postResult.json();

		if (postResult.ok) {
			$blockedReservation = {
				...$blockedReservation,
				...result.data,
			};
			newGuest.backgroundCheckId = result.data.aigKey;
			newGuest.backgroundcheckgroupId =
				result.data.backgroundcheckgroupId;

			backgroundCheckURL = result.data.applicationUrl;
		} else {
			console.error(result);
		}
	}
</script>

<style lang="scss">
	.payment-form {
		background-color: #fff;
		margin-bottom: 15px;

		.zenya-guest-type-container {
			margin-bottom: 15px;
			background-color: rgba(0, 0, 0, 0.05);
			border-radius: 3px;
			padding: 1px 1em;

			.zenya-guest-type-instructions {
				font-size: 0.9em;
				margin: 5px 0;
			}
		}

		.zenya-booking-form-row {
			display: grid;
			margin: 0 0px 10px;
			column-gap: 12px;
			grid-template-columns: 1fr;

			&.zenya-booking-form-grid {
				grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
			}

			&.zenya-booking-row-split {
				grid-template-columns: 1fr 1fr;
			}

			&.zenya-booking-row-address {
				grid-template-columns: 3fr 1fr 1fr;
			}

			/*
			.zenya-booking-field {
				position: relative;
				width: 100%;
				min-height: 2.5em;
				padding: 0 2px;
				// margin: 0 10px;

				.baseline {
					position: absolute;
					width: 100%;
					height: 1px;
					left: 0;
					bottom: 0;
					background-color: #cfd7df;
					transition: background-color 0.3s
						cubic-bezier(0.165, 0.84, 0.44, 1);
				}

				label:not(.checkbox) {
					padding: 0 !important;
					margin-bottom: 0;
					z-index: 5;
					background: #fff !important;
					border: 0 !important;
					font-size: 16px !important;
					position: absolute;
					width: 100%;
					left: 0;
					top: 1px;
					color: #cfd7df;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					transform-origin: 0 50%;
					cursor: text;
					pointer-events: none;
					transition-property: color, transform;
					transition-duration: 0.3s;
					transition-timing-function: cubic-bezier(
						0.165,
						0.84,
						0.44,
						1
					);
				}

				.input:not([type="checkbox"]) {
					position: absolute;
					height: 1.3em !important;
					width: 100%;
					left: 0;
					bottom: 0;
					padding-bottom: 7px;
					color: #32325d;
					background-color: transparent;
				}

				.input::-webkit-input-placeholder {
					color: transparent;
					transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
				}
			}
			*/

			.zenya-booking-field:not(.checkbox) {
				display: block;
				padding: 9px 9px 1px;
				border: 1px solid #dddddd;
				margin: 4px 0 3px;
				border-radius: 3px;

				label:not(.checkbox) {
					display: block;
					float: left;
					margin-top: -19px;
					margin-bottom: 0;
					background: #fff !important;
					border: 0 !important;
					// height: 14px;
					padding: 2px 5px 2px 5px;
					color: #b9b9b9;
					font-size: 14px !important;
					overflow: hidden;
					// font-family: Arial, Helvetica, sans-serif;
				}
				.input:not([type="checkbox"]),
				textarea,
				select {
					box-sizing: border-box;
					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
					width: 100%;
					display: block;
					outline: none;
					border: none;
					// height: 25px;
					// line-height: 25px;
					// font-size: 16px;
					padding: 0;
					margin-bottom: 0;
					// font-family: Georgia, "Times New Roman", Times, serif;
				}
			}
		}
	}

	// Payment form field css

	.bookingForm {
		&.bookingFormField {
			.input::-moz-placeholder {
				color: transparent;
				transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
			}

			.input:-ms-input-placeholder {
				color: transparent;
				transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
			}

			.input.StripeElement {
				opacity: 0;
				transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
				will-change: opacity;
			}

			.input.focused,
			.input:not(.empty) {
				opacity: 1;
			}

			.input.focused::-webkit-input-placeholder,
			.bookingForm.bookingFormField
				.input:not(.empty)::-webkit-input-placeholder {
				color: #cfd7df;
			}

			.input.focused::-moz-placeholder,
			.input:not(.empty)::-moz-placeholder {
				color: #cfd7df;
			}

			.input.focused:-ms-input-placeholder,
			.input:not(.empty):-ms-input-placeholder {
				color: #cfd7df;
			}

			.input.focused + label,
			.input:not(.empty) + label {
				color: #aab7c4;
				transform: scale(0.75) translateY(-1.3em);
				cursor: default;
			}

			.input.focused + label {
				color: #24b47e;
			}

			.input.invalid + label {
				color: #ffa27b;
			}

			.input.focused + label + .baseline {
				background-color: #24b47e;
			}

			.input.focused.invalid + label + .baseline {
				background-color: #e25950;
			}

			input:not([type="checkbox"]),
			input:not([type="radio"]),
			button {
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				outline: none;
				border-style: none;
			}

			input[type="checkbox"],
			input[type="radio"] {
				-webkit-appearance: auto;
				-moz-appearance: auto;
				appearance: auto;
				outline: initial;
				border-style: initial;
			}

			input:-webkit-autofill {
				-webkit-text-fill-color: #e39f48;
				transition: background-color 100000000s;
				-webkit-animation: 1ms void-animation-out;
			}

			.StripeElement--webkit-autofill {
				background: transparent !important;
			}

			input,
			button {
				-webkit-animation: 1ms void-animation-out;
			}

			button {
				display: block;
				// width: 100%;
				height: 40px;
				border-radius: 4px;
				color: #fff;
				text-transform: uppercase;
				font-weight: 600;
				cursor: pointer;
			}
		}

		.zenya-form-buttons {
			display: flex;
			flex-direction: row;
			margin-top: 2em;

			& > .zenya-booking-button {
				flex: none;
			}
		}
	}
	div#zenya-booking-card {
		// 	position: absolute;
		// 	bottom: 0;
		// 	left: 0;
		// 	right: 0;
		height: 23px;
		margin-top: 7px;
	}

	.zenya-contact-modal-wrapper {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: rgba(255, 255, 255, 0.6);
		background: rgba(0, 0, 0, 0.4);
		display: flex;
		align-items: center;
		justify-content: center;
		backdrop-filter: blur(2px);
		z-index: 210;

		.zenya-contact-modal-container {
			padding: 10px;
			background-color: white;
			border-radius: 4px;
			box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.7);
			min-width: 50vw;
			min-height: 50vh;
			max-width: 90vw;
			max-height: 90vh;
			display: flex;
			flex-direction: column;
			overflow-y: auto;

			.zenya-contact-modal-content {
				flex: 1;
			}
		}
	}

	.hidden {
		display: none;
	}

	.zenya-cc-note {
		padding: 5px;
		margin-bottom: 5px;
	}

	.zenya-background-check {
		.zenya-bgcheck-start {
			padding: 1em;
		}

		.zenya-background-check-iframe {
			width: 100%;
			height: 75vh;
		}
	}
</style>
