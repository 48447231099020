<!-- {#if $buildings && $buildings.length}
	<GoogleMap
		apiKey="AIzaSyBSPMHKcKRXM27KGZX53-Z_lhqDRVC3FVs"
		center={mapsCenter}
	>
		{#each $buildings as res, i (res.tagvalueId)}
			<GoogleMapMarker lat={res.lat} lng={res.lng} />
		{/each}
	</GoogleMap>
{/if} -->
<button
	class="zenya-booking-button"
	id="tog-map-but"
	hidden={isNarrowScreen}
	on:click={() => (isFullScreen = !isFullScreen)}
>
	Toggle Map
</button>
<div id="main-container" class:side={isFullScreen && !isNarrowScreen}>
	<div
		id="building-result-map"
		hidden={$_config.hideSearchResultMap == "Hide" && !isFullScreen}
		class:available={$buildings && $buildings.length && map}
	/>
	<div id="building-results">
		{#if sortedBuildings && sortedBuildings.length}
			<div id="zenya-building-result-sorting">
				<input
					type="checkbox"
					bind:checked={sortByAvailability}
					on:change={sortBuildingResults}
				/> Available Results First
			</div>
			<div class="zenya-result-grid">
				{#each sortedBuildings as res, i (res.tagvalueId)}
					<div
						class="zenya-card"
						in:fade={{ duration: 400, delay: i * 100 }}
					>
						<div class="zenya-card-image">
							{#if res.buildingImages && res.buildingImages.length}
								<Carousel perPage={1}>
									<span class="control" slot="left-control">
										<ChevronLeftIcon />
									</span>
									{#each res.buildingImages as image, i}
										<div class="aspect-ratio-box">
											<img
												style="object-fit: cover;"
												src={image}
												alt="{res.buildingName}{i}"
											/>
										</div>
									{/each}
									<span class="control" slot="right-control">
										<ChevronRightIcon />
									</span>
								</Carousel>
							{/if}
						</div>
						<div class="zenya-tile-content">
							<div class="zenya-tile-title">
								{res.buildingName}
								<div class="zenya-distance">
									({res.distance}mi)
								</div>
							</div>
							<div class="zenya-tile-subtitle">{res.address}</div>

							{#if res.buildingAmenities}
								<div class="zenya-tile-icons">
									{#each res.buildingAmenities as icon}
										<div
											aria-label={icon.description}
											data-microtip-position="top"
											role="tooltip"
										>
											<img
												class="zenya-tile-icon"
												src={icon.icon}
											/>
										</div>
									{/each}
								</div>
							{/if}

							{#if res.buildingDescription && (!$_config.buildingDescription || ["Both", "Building Results"].includes($_config.buildingDescription))}
								<p class="zenya-tile-description">
									{res.buildingDescription}
								</p>
							{/if}

							<!-- {#if $_config.showRates == 'Always' || ($_config.showRates == 'Booking Code Entered' && $requestInfo.bookingCode && res.startingPrice > 0)}
							<div class="zenya-tile-details">
								From ${res.startingPrice}/night
							</div>
						{/if} -->

							{#if $_config.buildingMinStayDisplay == "Include" && $_config.buildingMinStayMessage && res.buildingMinStay && res.shortStay}
								<div class="zenya-stay-length-alert">
									{$_config.buildingMinStayMessage}
									{res.buildingMinStay} days
								</div>
							{/if}

							{#if $_config.roomResultType == "Simple"}
								<div class="zenya-tile-actions">
									{#if res.buildingHideRoomType != "Yes"}
										<!-- ROOM TYPES NOT HIDDEN -- SHOW ALL -->
										{#each res.rooms as room}
											<!-- LIST SIMPLE ROOM RESULTS -->
											<RoomResultActions
												building={res}
												{room}
											/>
										{/each}
									{:else}
										<!-- ROOM TYPES ARE HIDDEN -- ONLY SHOW THE FIRST -->
										<RoomResultActions
											building={res}
											room={res.rooms[0]}
										/>
									{/if}
								</div>
							{:else}
								<!-- LINK TO DETAILS ROOM RESULTS -->
								<div class="zenya-tile-actions">
									<button
										class="zenya-booking-button"
										on:click={choseBuilding(res)}
										>View
										{$_config.roomAlt}s</button
									>
								</div>
							{/if}
						</div>
					</div>
				{/each}
			</div>
			<UnavailableComponent bind:this={unavailable} />
		{:else}
			<h3 style="text-align: center;">
				There were no results for this search. Try again with different
				options, or <a href="#" on:click={contact}>contact us</a> directly
			</h3>

			<ContactForm />
		{/if}
	</div>
</div>

<script>
	import { getContext, onMount, tick, afterUpdate } from "svelte";
	import Carousel from "@beyonk/svelte-carousel";
	import { ChevronLeftIcon, ChevronRightIcon } from "svelte-feather-icons";
	import { fade } from "svelte/transition";
	import { route, _loading } from "./RoutingStore";
	import { buildings, currentBuilding, currentRoom } from "./RoomStore";
	import { _config } from "./ConfigStore";
	import { requestInfo, blockedReservation } from "./BookingStore";
	import dayjs from "dayjs";
	import UnavailableComponent from "./UnavailableComponent.svelte";
	import RoomResultActions from "./RoomResultActions.svelte";
	import ContactForm from "./ContactForm.svelte";

	import { GoogleMap, GoogleMapMarker } from "@beyonk/svelte-googlemaps";
	import loader from "@beyonk/async-script-loader";

	let unavailable;
	let sortByAvailability = true;

	let windowWidth = window.innerWidth;
	let isNarrowScreen = false;
	let isFullScreen = false;

	let sortedBuildings = [];

	let post = getContext("fetch");

	function choseBuilding(building) {
		currentBuilding.set({ ...building });
		route.set("units");
	}

	function contact() {
		route.set("contact");
	}

	let mapsCenter = {
		lat: $requestInfo.location.lat,
		lng: $requestInfo.location.long,
	};

	let map;
	let markers;

	async function sortBuildingResults() {
		sortedBuildings = [];
		await tick();
		if (sortByAvailability) {
			sortedBuildings = [...$buildings].sort((a, b) => {
				a.hasAvailableUnits ? -1 : b.hasAvailableUnits ? 1 : 0;

				if (a.hasAvailableUnits != b.hasAvailableUnits) {
					return +b.hasAvailableUnits - +a.hasAvailableUnits;
				} else {
					return a.distance - b.distance;
				}
			});
		} else {
			sortedBuildings = [...$buildings];
		}
	}

	function handleResize() {
		windowWidth = window.innerWidth;
	}

	onMount(() => {
		if ($_config.hideSearchResultMap != "Hide" && window.google) {
			// loader(
			// 	[
			// 		{
			// 			type: "script",
			// 			url: "//maps.googleapis.com/maps/api/js?key=AIzaSyBSPMHKcKRXM27KGZX53-Z_lhqDRVC3FVs&amp;libraries=places",
			// 		},
			// 	],
			// 	() => {
			// 		return !!window.google;
			// 	},
			// 	() => {

			// 	},
			// );
			map = new google.maps.Map(
				document.getElementById("building-result-map"),
				{
					center: mapsCenter,
					zoom: 11,
					scrollwheel: true,
				},
			);
			if ($buildings && $buildings.length) {
				setUpMap();
			}
		}

		buildings.subscribe((r) => {
			sortBuildingResults();

			if (map) {
				setUpMap();
			}
		});

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});

	function setUpMap() {
		if (map) {
			map.panTo({
				lat: $requestInfo.location.lat,
				lng: $requestInfo.location.long,
			});
			if (markers && markers.length) {
				markers.forEach((m) => m.setMap(null));
			}

			let currentInfoWindow = null;
			markers = $buildings.map((b) => {
				let marker = new google.maps.Marker({
					position: { lat: b.lat, lng: b.lng },
					title: b.buildingName,
					map,
				});
				const urlObj = new URL(window.location.href);
				urlObj.searchParams.set("buildingId", b.rooms[0].buildingId);
				let buildingUrl = urlObj.toString();

				let infoWindow = new google.maps.InfoWindow({
					content: `<a href="${buildingUrl}" target="_blank">${b.buildingName}</a>`,
					//content: `<a href="${window.location.origin}?buildingId=${b.rooms[0].buildingId}" target="_blank">${b.buildingName}</a>`,
				});

				marker.addListener("click", function () {
					if (currentInfoWindow) {
						currentInfoWindow.close();
					}
					infoWindow.open(map, marker);
					currentInfoWindow = infoWindow;
				});

				return marker;
			});

			google.maps.event.addListener(map, "click", function () {
				if (currentInfoWindow) {
					currentInfoWindow.close();
				}
			});

			var LatLngList = markers.map(
				(m) => new google.maps.LatLng(m.position),
			);
			//  Create a new viewpoint bound
			var bounds = new google.maps.LatLngBounds();
			//  Go through each...
			for (var i = 0, LtLgLen = LatLngList.length; i < LtLgLen; i++) {
				//  And increase the bounds to take this point
				bounds.extend(LatLngList[i]);
			}
			//  Fit these bounds to the map
			map.fitBounds(bounds);
		}
	}

	$: isNarrowScreen = windowWidth <= 875;
</script>

<style type="text/scss">
	// @import "microtip/microtip";

	// [role~="tooltip"][data-microtip-position|="top"]::before {
	// 	margin-bottom: -5px;
	// }
	// [role~="tooltip"][data-microtip-position|="top"]::after {
	// 	margin-bottom: 1px;
	// }

	#main-container {
		display: flex;
		flex-direction: column;

		&.side {
			flex-direction: row;
			width: calc(100vw - 20px);
			margin-left: calc(-50vw + 50% + 8px);

			#building-result-map {
				flex: 3;
				position: sticky !important;
				top: 0px;
				height: 100vh;
				margin-right: 10px;
			}

			#building-results {
				flex: 2;
			}
		}

		#building-result-map {
			aspect-ratio: 21/9;
			margin-bottom: 2rem;
			border-radius: 4px;
			display: none;
			width: 100%;

			&.available:not([hidden]) {
				display: block;
			}
		}
	}

	#tog-map-but {
		display: block;
		margin-left: auto;
		margin-bottom: 10px;
	}

	.zenya-result-grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
		grid-gap: 1rem;
	}

	.zenya-stay-length-alert {
		padding: 0.25em 0.5em 0.4em 0.5em;
		margin: 0.5em 0 0.25em 0;
		text-align: center;
		font-size: 0.9em;

		border: 1px solid rgb(188, 232, 241);
		border-radius: 4px;
		background-color: rgb(217, 237, 247);
		color: rgb(58, 135, 173);
	}

	.zenya-room-result {
		background: whitesmoke;
		border: 1px solid lightgray;
		border-radius: 4px;
		padding: 6px;
		margin-bottom: 10px;
		font-size: 0.7em;
		display: flex;

		.zenya-room-result-content {
			flex: 1;

			.zenya-tile-title {
				font-weight: 500 !important;
				font-size: 1.2em !important;
			}

			.zenya-tile-details-price {
				// display: flex;
				// flex: 1;
				// align-items: baseline;

				font-size: 1.3em;
				text-align: left;

				.zenya-price-suffix {
					// flex: 1;
					// text-align: end;
					display: inline;
				}
			}
		}

		.zenya-tile-actions {
			display: grid;
			// grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
			grid-column-gap: 7px;
			grid-template-columns: 1fr 1fr;

			button.zenya-booking-button {
				font-size: 1em;
				padding: 6px 10px !important;
				margin-top: 0 !important;
			}
		}
	}
</style>
