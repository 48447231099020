{#if $_config.leaseAgreementMessage}
	<SendLeaseComponent />
{/if}
<div class="agreement-layout">
	<form class="agreement-form" on:submit|preventDefault={submitAgreement}>
		{#if document}
			<quote class="document-container" bind:this={documentContainer}>
				<h3>{document.documentName}</h3>
				{#if document.documentHTMLinput}
					{@html document.documentHTMLinput}
				{/if}
			</quote>
		{/if}

		<div class="form-section">
			<label>
				<input
					type="checkbox"
					name="terms"
					required
					bind:checked={formData.signedAgreement}
				/>
				I Agree
			</label>

			<div class="zenya-signature-row">
				<div>
					<canvas
						bind:this={sigCanvas}
						width="350"
						height="100"
						style="border-bottom: 2px dashed lightgray;"
					/>
					<div class="sign-instructions">Sign on dashed line</div>
				</div>
				{#if document}
					<div>
						{#if document.requirePrintedName}
							<label>
								Print Name
								<input bind:value={formData.printedName} />
							</label>
						{/if}
						{#if document.requireDate}
							<label>
								Today's Date
								<input bind:value={formData.signedOn} />
							</label>
						{/if}
					</div>
				{/if}
			</div>
		</div>
		<div class="form-section">
			<button
				class="zenya-booking-button zenya-sign-agreement-submit"
				type="submit">Submit</button
			>
		</div>
		{#if formError}
			<div class="zenya-form-error">
				{formError}
			</div>
		{/if}
	</form>
</div>

<script>
	import { _config } from "./ConfigStore";

	import { route, _loading } from "./RoutingStore";
	import { bookingInfo } from "./BookingStore";

	import SignaturePad from "signature_pad";

	import SendLeaseComponent from "./SendLeaseComponent.svelte";
	import { onMount, getContext } from "svelte";

	let documentContainer;
	let sigCanvas;
	let sigPad;

	let document;
	let post = getContext("fetch");

	onMount(async () => {
		// console.log(sigCanvas);
		_config.subscribe(async (config) => {
			if (config.agreementDocument) {
				_loading.set(true);
				let postResult = await post("getAgreement", {
					customerGuestInstanceId: $bookingInfo.guestId,
					customerId: $bookingInfo.customerId,
					customerDocumentId:
						$_config.agreementDocument.customerDocumentId,
				});

				let result = await postResult.json();
				_loading.set(false);
				// console.log(result);
				if (postResult.ok && result.data && result.data.length) {
					document = result.data[0];

					document.documentHTMLinput = document.documentHTML.replace(
						/\[(?<input>.+?)\|(?<type>.+?)\|(?<name>.+?)\|(?<placeholder>.+?)(\|(?<required>.+?))?\]/g,
						(
							match,
							input,
							type,
							name,
							placeholder,
							reqGroup,
							required,
							offset,
							string,
							groups
						) => {
							if (groups.type == "textarea") {
								return `<textarea type="${groups.type}" name="${
									groups.name
								}" placeholder="${
									groups.placeholder
								}" class="document-input" ${
									groups.required ? "required" : ""
								} ></textarea>`;
							} else {
								let fileProps =
									groups.type == "file"
										? 'accept="image/*"'
										: "";
								return `<input type="${groups.type}" name="${
									groups.name
								}" placeholder="${
									groups.placeholder
								}" ${fileProps} class="document-input" ${
									groups.required ? "required" : ""
								} />`;
							}
						}
					);

					if (document.requireSignature) {
						sigPad = new SignaturePad(sigCanvas);
					}
				} else {
					console.error(result);
				}
			}
		});
	});

	let formData = {};
	let formError;

	async function submitAgreement(e) {
		let documentInputs =
			documentContainer.querySelectorAll(".document-input");

		// console.log(documentInputs);
		let error = "";

		let missingRequired = false;
		documentInputs.forEach((i) => {
			if (
				i.hasAttribute("required") &&
				!(i.value || i.checked || (i.files && i.files.length))
			) {
				missingRequired = true;
			}
		});
		if (missingRequired) {
			error += "Please enter all required fields. ";
		}

		if (!error || !error.length) {
			formError = null;
			let mapped = await Promise.all(
				Array.from(documentInputs).map(async (i) => {
					var returnObj = {
						name: i.name,
						value: i.value,
					};
					if (i.type == "checkbox") {
						returnObj.value = i.checked ? "[X]" : "[ ]";
						return returnObj;
					} else if (
						i.type == "file" &&
						i.files &&
						i.files.length &&
						i.files[0].type.includes("image")
					) {
						returnObj.value = await asyncImage(i.files[0]);
					}
					return returnObj;
				})
			);

			formData.inputs = mapped;

			if (sigPad) {
				formData.signature = sigPad.toDataURL();
			}

			let agreementObject = {
				...document,
				...formData,
				customerGuestInstanceId: $bookingInfo.guestId,
				customerId: $bookingInfo.customerId,
			};

			_loading.set(true);
			let postResult = await post(
				"submitSignedAgreement",
				agreementObject
			);
			_loading.set(false);

			if (postResult.ok) {
				let result = await postResult.json();
				bookingInfo.set({ ...$bookingInfo, ...result.data });

				/// PROCESS PAYMENTS -> SUBMIT PAYMENTS TO STRIPE
				_loading.set(true);
				let paymentPostResult = await post("processPayments", {
					payments: $bookingInfo.paymentObjects,
				});
				_loading.set(false);

				if (paymentPostResult.ok) {
					let paymentResult = await paymentPostResult.json();
					bookingInfo.set({ ...$bookingInfo, ...paymentResult.data });

					/// SHOW CONFIRMATION
					route.set("confirmation");
				} else {
					console.error(await paymentPostResult.json());
				}
			} else {
				console.error(await postResult.json());
			}
		} else {
			formError = error;
		}
	}

	function asyncImage(file) {
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.addEventListener("load", () => resolve(reader.result));
			reader.readAsDataURL(file);
		});
	}
</script>

<style type="text/scss">
	/* your styles go here */
	.agreement-layout {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.booking-details {
			flex: 1;
		}
		.agreement-form {
			flex: 2;

			.form-section {
				margin-bottom: 10px;
				padding: 10px;

				.zenya-signature-row {
					display: flex;

					label {
						margin: 1em;
					}
				}

				.zenya-form-group {
					display: flex;

					label {
						flex: 1;
						margin: 5px;
					}
				}
			}
		}
	}

	.zenya-form-error {
		color: red;
	}

	.sign-instructions {
		font-size: 0.75em;
		color: darkgray;
	}
</style>
