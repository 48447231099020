import * as Sentry from '@sentry/svelte';
import env from './env';

class SentryAnalytics {
    static _instance;

    static get instance() {
        if (!SentryAnalytics._instance) {
            SentryAnalytics._instance = new SentryAnalytics();
        }
        return SentryAnalytics._instance;
    }

    init() {
        Sentry.init({
            dsn: env.sentryDsn,
            enabled: true,
            release: `booking-widget@${env.release}`,
            debug: true,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.browserProfilingIntegration(),
                Sentry.replayIntegration(),
            ],

            initialScope: {
                tags: {
                    service: 'frontend',
                    app: 'booking-widget',
                },
            },

            tracesSampleRate: env.tracesSampleRate,
            tracePropagationTargets: [env.propagationTarget],
            replaysSessionSampleRate: env.replaysSessionSampleRate,
            replaysOnErrorSampleRate: env.replaysOnErrorSampleRate,
        });
    }

    async track(eventName, properties) {
        Sentry.captureMessage(eventName);

        if (properties) {
            Object.keys(properties).forEach((key) => {
                Sentry.setTag(key, properties[key].toString());
            });
        }
    }

    async trackEvent(event) {
        return this.track(event.name, event.properties);
    }

    async asUser(userId, email, name, mobile) {
        if (userId == null) {
            Sentry.setUser(null);
            return Promise.resolve();
        }

        Sentry.withScope((scope) => {
            scope.setUser({
                id: userId,
                email: email || undefined,
                username: name || undefined,
                extras: {
                    mobile: mobile || undefined,
                },
            });
        });
    }

    async setProfileProperty(name, value) {
        Sentry.withScope((scope) => {
            scope.setTag(name, value.toString());
        });
    }

    async setProfilePropertyOnce(name, value) {
        return this.setProfileProperty(name, value);
    }

    async setProperty(name, value) {
        return this.setProfileProperty(name, value);
    }

    async setProfileProperties(properties) {
        Sentry.withScope((scope) => {
            Object.keys(properties).forEach((key) => {
                scope.setTag(key, properties[key].toString());
            });
        });
    }

    async setProfilePropertiesOnce(properties) {
        return this.setProfileProperties(properties);
    }

    async setPropertiesOnce(properties) {
        return this.setProfileProperties(properties);
    }

    async disableTracking() {
        Sentry.withScope((scope) => {
            scope.setLevel("log");
        });
    }

    async flush() {
        return Sentry.flush();
    }

    async setCurrentScreen(screenName) {
        Sentry.withScope((scope) => {
            scope.setTransactionName(screenName);
        });
    }
}

export const analytics = SentryAnalytics.instance;
