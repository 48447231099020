<div class="details-container">
	<div class="details-layout">
		<div class="share-container">
			{#if !copiedShareUrl}
				<div class="share-message" in:fade={{ duration: 400 }}>
					Share
				</div>
			{/if}
			{#if copiedShareUrl}
				<div class="share-message" in:fade={{ duration: 400 }}>
					URL Copied
				</div>
			{/if}
			<button class="icon-button" on:click={copyShareURL}>
				<img src="{envURL}assets/share-icon.svg" />
			</button>
		</div>
		{#if $currentRoom.deal}
			<!-- content here -->
			<div class="zenya-room-details">
				<div class="details-building-name">Option Details</div>
				<div>
					${$currentRoom.deal.leaseRate}
					{$currentRoom.deal.leaseRateType}
				</div>
				<div>
					<strong>Between</strong>:
					{dayjs($currentRoom.deal.SessionStartDate).format(
						"MM/DD/YYYY",
					)}
					/ {dayjs($currentRoom.deal.SessionEndDate).format(
						"MM/DD/YYYY",
					)}
				</div>
				{#if $currentRoom.deal.notes}
					<div>
						<em>
							{$currentRoom.deal.notes}
						</em>
					</div>
				{/if}

				{#if $currentRoom.deal.expirationDate && new Date($currentRoom.deal.expirationDate.split("T")[0]) < new Date()}
					<div>
						<strong> The deal has been expired now! </strong>
					</div>
				{/if}

				{#if $currentRoom.deal.expirationDate && new Date($currentRoom.deal.expirationDate.split("T")[0]) > new Date()}
					<div>
						<strong>Expires</strong>: {dayjs(
							$currentRoom.deal.expirationDate,
						).format("MM/DD/YYYY")}
					</div>
				{/if}

				<div>Configuration: legal text</div>
				{#if $currentRoom.deal.dealStatusId == 4}
					<div>
						<strong class="accept-text">
							This deal has been already accepted by you.
						</strong>
					</div>
				{/if}
				{#if $currentRoom.deal.dealStatusId == 3}
					<div class="reject-text" id="reject-text">
						<strong> This deal has been rejected by you. </strong>
					</div>
				{/if}
				{#if $currentRoom.deal.dealStatusId != 4 && $currentRoom.deal.dealStatusId != 3}
					<div class="zenya-details-button-row">
						<button
							class="zenya-booking-button"
							on:click={acceptDeal}
							type="button"
							id="deal-accept">Accept</button
						>
						<button
							class="zenya-booking-button"
							on:click={rejectDeal}
							type="button"
							id="deal-rejject">Reject</button
						>
					</div>
				{/if}
			</div>
		{:else}
			{#if $_config.roomLinkDirectBooking == "Enabled" && !$currentRoom.price && (!$requestInfo || (!$requestInfo.startDate && !$requestInfo.endDate))}
				<div class="update-request-section">
					<h4>
						{$_config.roomLinkDirectBookingMessage ||
							"More information is required for direct booking"}
					</h4>
					<form
						on:submit|preventDefault={updateRoomWithBookingInfo}
						class="update-form"
					>
						{#if flatpickrOptions && flatpickrOptions.mode}
							<label class="zenya-custom-flatpickr">
								Dates
								<Flatpickr
									options={flatpickrOptions}
									name="dates"
									required
								/>
							</label>
						{/if}
						{#if $_config.bookingCodeType != "Disabled"}
							<label>
								Booking Code
								<input
									name="bookingCode"
									type="text"
									bind:value={formData.bookingCode}
								/>
								{#if $_config.bookingCodeHint}
									<div class="zenya-field-hint">
										{$_config.bookingCodeHint}
									</div>
								{/if}
							</label>
						{/if}
						<button
							type="submit"
							class="zenya-search-button zenya-booking-button"
							value="Search">Submit</button
						>
					</form>
					{#if searchError && searchError.length}
						<div class="zenya-search-error">{searchError}</div>
					{/if}
				</div>
			{/if}
			<div class="zenya-details">
				<div class="zenya-details-button-row">
					<div class="zenya-details-price">
						{#if $currentRoom.price && ($_config.showRates == "Always" || ($_config.showRates == "Booking Code Entered" && $requestInfo.bookingCode))}
							{currency($currentRoom.price).format()}
							{$currentRoom.rate.pricetypename}

							{#if $_config.priceSuffix}
								<div class="zenya-price-suffix">
									{$_config.priceSuffix}
								</div>
							{/if}
						{/if}
					</div>
					<div style="flex: 1;" />
					<div>
						{#if $currentRoom.price && !$currentRoom.requestOnly && ($_config.showRates == "Always" || ($_config.showRates == "Booking Code Entered" && $requestInfo.bookingCode))}
							<button
								class="zenya-booking-button"
								on:click={bookRoom}
								type="button"
								id="details-book"
							>
								Book
							</button>
						{:else}
							<button
								class="zenya-booking-button"
								on:click={contact}
								type="button"
								id="details-contact">Request</button
							>
						{/if}
					</div>
				</div>
				<!-- {#if $currentRoom.price && ($_config.showRates == "Always" || ($_config.showRates == "Booking Code Entered" && $requestInfo.bookingCode)) && $currentRoom.additionalFees && $currentRoom.additionalFees.length}
					<div class="zenya-details-additional-info">
						{#each $currentRoom.additionalFees as fee}
							<div>
								{fee.description} - {currency(fee.price).format()}
								{fee.pricetypename}
							</div>
						{/each}
					</div>
				{/if} -->
			</div>
		{/if}
		<div class="zenya-building-details">
			<div class="details-building-name">
				{$currentBuilding.buildingName}
			</div>
			<div class="details-building-address">
				{$currentBuilding.address}
			</div>
			{#if $currentBuilding.buildingAmenities}
				<div class="zenya-details-icons">
					{#each $currentBuilding.buildingAmenities as icon}
						<div
							aria-label={icon.description}
							data-microtip-position="top"
							role="tooltip"
						>
							<img class="zenya-details-icon" src={icon.icon} />
						</div>
					{/each}
				</div>
			{/if}
			{#if $currentBuilding.buildingDescription && (!$_config.buildingDescription || ["Both", "Room Details"].includes($_config.buildingDescription))}
				<div>{$currentBuilding.buildingDescription}</div>
			{/if}
		</div>
		<div class="zenya-room-details">
			<div class="details-header details-room-name">
				{#if $currentBuilding.buildingHideRoomType != "Yes"}
					{$currentRoom.roomType}
				{/if}
				{#if $currentRoom.price && ($_config.showRates == "Always" || ($_config.showRates == "Booking Code Entered" && $requestInfo.bookingCode))}
					- {currency($currentRoom.price).format()}
					{$currentRoom.rate.pricetypename}
				{/if}
				<div style="flex: 1;" />

				{#if $currentRoom.price && !$currentRoom.requestOnly && ($_config.showRates == "Always" || ($_config.showRates == "Booking Code Entered" && $requestInfo.bookingCode))}
					<button
						class="zenya-booking-button"
						on:click={bookRoom}
						type="button"
						id="details-book"
					>
						Book
					</button>
				{:else}
					<button
						class="zenya-booking-button"
						on:click={contact}
						type="button"
						id="details-contact">Request</button
					>
				{/if}
			</div>
			<div class="details-row">
				{#if $currentRoom.roomDescription}
					<div class="details-description">
						<h4 class="details-subheader">Description</h4>
						<p>{$currentRoom.roomDescription}</p>
					</div>
				{/if}
				{#if $currentRoom.roomAmenities}
					<div class="details-amenities">
						<h4 class="details-subheader">
							{$_config.roomDetailsMoreInfo || "Amenities"}
						</h4>
						{$currentRoom.roomAmenities}
					</div>
				{/if}
			</div>
			{#if $currentRoom.roomInfo && $currentRoom.roomInfo.length}
				<div class="details-row">
					<div class="details-additional">
						<h4 class="details-subheader">More Information</h4>
						{#each $currentRoom.roomInfo as info}
							<div class="details-additional-item">
								{info}
							</div>
						{/each}
					</div>
				</div>
			{/if}
		</div>
	</div>

	{#if (imageList && imageList.length) || ($currentBuilding && $currentBuilding.lat && $currentBuilding.lng)}
		<div class="details-layout">
			{#if imageList && imageList.length}
				<div class="carousel-container">
					<Carousel perPage={1}>
						<span class="control" slot="left-control">
							<ChevronLeftIcon />
						</span>
						{#each imageList as image, i}
							<div class="aspect-ratio-box">
								<!-- <div
								class="full-screen-button"
								on:click={(e) => setFullImage(image)}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="28"
									height="28"
									viewBox="0 0 18 18"
								>
									<path
										d="M4.5 11H3v4h4v-1.5H4.5V11zM3 7h1.5V4.5H7V3H3v4zm10.5 6.5H11V15h4v-4h-1.5v2.5zM11 3v1.5h2.5V7H15V3h-4z"
									/>
								</svg>
							</div> -->
								<img
									style="object-fit: cover;"
									src={image}
									alt="{$currentRoom.name}{i}"
								/>
							</div>
						{/each}
						<span class="control" slot="right-control">
							<ChevronRightIcon />
						</span>
					</Carousel>
				</div>
			{/if}

			<div
				id="building-result-map"
				class:available={$currentBuilding &&
					$currentBuilding.lat &&
					$currentBuilding.lng}
			/>
		</div>
	{/if}
</div>

{#if $currentRoom && $currentRoom.rate && $currentRoom.price && ($_config.showRates == "Always" || ($_config.showRates == "Booking Code Entered" && $requestInfo.bookingCode))}
	<div class="details-layout">
		<TotalCalc estimator={true} />
	</div>
{/if}
<UnavailableComponent bind:this={unavailable} />

<!-- {#if fullImage}
	<div class="full-screen" fade on:click={(e) => setFullImage(null)}>
		<div class="image-container"><img src={fullImage} /></div>
	</div>
{/if} -->
<script>
	import { getContext, onMount } from "svelte";
	import { fade } from "svelte/transition";
	import Carousel from "@beyonk/svelte-carousel";
	import { ChevronLeftIcon, ChevronRightIcon } from "svelte-feather-icons";
	import { bookable, currentBuilding, currentRoom } from "./RoomStore";
	import { route, _loading } from "./RoutingStore";
	import { requestInfo, blockedReservation } from "./BookingStore";
	import { _config } from "./ConfigStore";
	import currency from "currency.js";
	import loader from "@beyonk/async-script-loader";

	import Flatpickr from "svelte-flatpickr";
	import dayjs from "dayjs";
	import UnavailableComponent from "./UnavailableComponent.svelte";
	import TotalCalc from "./TotalCalc.svelte";

	let unavailable;

	if (!$currentRoom.roomImages || $currentRoom.roomImages.length == 0) {
		$currentRoom.roomImages = [];
	}
	if (
		!currentBuilding.buildingImages ||
		currentBuilding.buildingImages.length == 0
	) {
		currentBuilding.buildingImages = [];
	}

	let imageList = [];
	if($_config.hideBuildingImageOnUnitDetails == 'Hide'){
		 imageList = $currentRoom.roomImages;
	
	}else{
		 imageList = $currentRoom.roomImages.concat(
		$currentBuilding.buildingImages,
	);
	}
	

	let fullImage;

	let post = getContext("fetch");

	let envURL = getContext("envURL");

	let formData = {
		startDate: null,
		endDate: null,
		bookingCode: "",
	};
	let searchError;

	let flatpickrOptions;
	let lastDisabled;

	let copiedShareUrl = false;
	let copyTimeout;
	const params = new URLSearchParams(window.location.search);
	params.set("unitId", $currentRoom.roomId);
	let shareURL =
		window.location.protocol +
		"//" +
		window.location.host +
		window.location.pathname +
		"?" +
		params.toString();

	let map, marker;
	onMount(() => {
		if (params.has("BookingCode")) {
			formData.bookingCode = params.get("BookingCode");
		}

		loader(
			[
				{
					type: "script",
					url: "//maps.googleapis.com/maps/api/js?key=AIzaSyBSPMHKcKRXM27KGZX53-Z_lhqDRVC3FVs&amp;libraries=places",
				},
			],
			() => {
				return !!window.google;
			},
			() => {
				currentBuilding.subscribe((r) => {
					if (r.lat && r.lng) {
						map = new google.maps.Map(
							document.getElementById("building-result-map"),
							{
								center: { lat: r.lat, lng: r.lng },
								zoom: 16,
							},
						);
						marker = new google.maps.Marker({
							position: { lat: r.lat, lng: r.lng },
							title: r.buildingName,
							map,
						});
					}
				});
			},
		);
	});

	let unsub = _config.subscribe((res) => {
		if (res && Object.keys(res).length) {
			let start = dayjs();
			if (res.minBookingWindow) {
				start = start.add(res.minBookingWindow, "d");
			}
			let end = start.add(1, "d");
			if (res.minStayLength) {
				end = start.add(res.minStayLength, "d");
			}

			flatpickrOptions = {
				mode: "range",
				dateFormat: "m/d/Y",
				minDate: start.format("M/D/YYYY"),
				enableTime: false,
				//defaultDate: [start.format("M/D/YYYY"), end.format("M/D/YYYY")],
				onChange: (selectedDates, dateStr, instance) => {
					if (selectedDates.length > 1) {
						formData.startDate = selectedDates[0];
						formData.endDate = selectedDates[1];
						formData.numberNights = Math.round(
							(formData.endDate.getTime() -
								formData.startDate.getTime()) /
								86400000,
						);
						if (lastDisabled) {
							instance.set("enable", [lastDisabled]);
						}
					} else {
						formData.startDate = null;
						formData.endDate = null;
						if (lastDisabled) {
							instance.set("enable", [lastDisabled]);
						}
					}
				},
				// onReady: (selectedDate, dateStr, instance) => {
				// 	instance.setDate(
				// 		[start.format("M/D/YYYY"), end.format("M/D/YYYY")],
				// 		true,
				// 	);
				// },
			};
		}
	});

	async function updateRoomWithBookingInfo() {
		if (
			formData.startDate &&
			formData.endDate &&
			($_config.bookingCodeType != "Required" || formData.bookingCode)
		) {
			_loading.set(true);
			let postResult = await post("getRoomById", {
				roomId: $currentRoom.roomId,
				...formData,
			});
			if (postResult.ok) {
				_loading.set(false);
				let results = await postResult.json();
				if (results && results.data) {
					results = results.data;
					if (
						results.buildings &&
						results.buildings.length &&
						results.buildings[0].rooms &&
						results.buildings[0].rooms.length
					) {
						requestInfo.set(formData);
						currentBuilding.set(results.buildings[0]);
						currentRoom.set(results.buildings[0].rooms[0]);
						// route.set("roomdetails");
					} else {
						console.error("No results");
					}
				}
			} else {
				let results = await postResult.json();

				console.error(results);
				// if (results && results.data) {
				// 	results = results.data;
				// 	console.error(results);
				// }
			}
		} else {
			if (!formData.startDate || !formData.endDate) {
				searchError = "Both Dates are required";
			} else if (!formData.bookingCode) {
				searchError = "A booking is required";
			}
		}
	}

	async function bookRoom() {
		let nights =
			Math.round(
				dayjs($requestInfo.endDate).diff(
					$requestInfo.startDate,
					"days",
					true,
				),
			) + 1;

		_loading.set(true);
		let postResult = await post("blockReservation", {
			checkIn: $requestInfo.startDate,
			checkOut: $requestInfo.endDate,
			numberNights: nights,
			roomId: $currentRoom.roomId,
			roomNumber: $currentRoom.name,
		});
		_loading.set(false);

		let result = await postResult.json();

		// if (postResult.ok) {
		if (postResult.ok && result.data.available == true) {
			// console.log(result);
			blockedReservation.set(result.data);

			/// GET MORE ROOM DETAILS
			_loading.set(true);
			let postResult = await post("getRoomById", {
				roomId: $currentRoom.roomId,
				startDate: $requestInfo.startDate,
				endDate: $requestInfo.endDate,
				bookingCode: $requestInfo.bookingCode,
			});
			_loading.set(false);
			if (postResult.ok) {
				let results = await postResult.json();
				// errorMessage = null;
				if (results && results.data) {
					results = results.data;
					if (
						results.buildings &&
						results.buildings.length &&
						results.buildings[0].rooms &&
						results.buildings[0].rooms.length
					) {
						/// SET INFO AND ROUTE TO BOOKING FORM
						currentBuilding.set(results.buildings[0]);
						currentRoom.set(results.buildings[0].rooms[0]);
						route.set("booking");
					} else {
						console.error("No results");
					}
				}
			} else {
				let results = await postResult.json();
				console.error(results);
			}
		} else if (postResult.ok && result.data.available == false) {
			unavailable.setModalState(true);
			console.warn(
				"This unit is no longer available. Please try another",
			);
		} else {
			console.error(result);
		}
	}

	async function rejectDeal() {
		_loading.set(true);
		let postResult = await post("saveDeal", {
			customerGuestInstanceId: $currentRoom.deal.customerGuestInstanceId,
			customerTabletId: $currentRoom.deal.customerTabletId,
			dealStatusId: 4,
			estimatedSize: $currentRoom.deal.estimatedSize,
			realSize: $currentRoom.deal.realSize,
			reasonUnsuccessful: $currentRoom.deal.reasonUnsuccessful,
			reasonRenegotiated: $currentRoom.deal.reasonRenegotiated,
			notes: $currentRoom.deal.notes,
			openedDate: $currentRoom.deal.openedDate,
			closedDate: $currentRoom.deal.closedDate,
			expirationDate: $currentRoom.deal.expirationDate,
			dealIndex: $currentRoom.deal.dealIndex,
			leaseratetypeId: $currentRoom.deal.leaseratetypeId,
			rates: $currentRoom.deal.rates,
			dealId: $currentRoom.deal.dealId,
		});

		_loading.set(false);

		let result = await postResult.json();

		if (postResult.ok && result.data) {
			$currentRoom.deal = result.data;
		} else {
			console.error(result);
		}
	}

	async function acceptDeal() {
		_loading.set(true);
		let postResult = await post("saveDeal", {
			customerGuestInstanceId: $currentRoom.deal.customerGuestInstanceId,
			customerTabletId: $currentRoom.deal.customerTabletId,
			dealStatusId: 4,
			estimatedSize: $currentRoom.deal.estimatedSize,
			realSize: $currentRoom.deal.realSize,
			reasonUnsuccessful: $currentRoom.deal.reasonUnsuccessful,
			reasonRenegotiated: $currentRoom.deal.reasonRenegotiated,
			notes: $currentRoom.deal.notes,
			openedDate: $currentRoom.deal.openedDate,
			closedDate: $currentRoom.deal.closedDate,
			expirationDate: $currentRoom.deal.expirationDate,
			dealIndex: $currentRoom.deal.dealIndex,
			leaseratetypeId: $currentRoom.deal.leaseratetypeId,
			rates: $currentRoom.deal.rates,
			dealId: $currentRoom.deal.dealId,
		});
		_loading.set(false);

		let result = await postResult.json();

		if (postResult.ok && result.data) {
			$currentRoom.deal = result.data;
		} else {
			console.error(result);
		}
	}

	function contact() {
		route.set("contact");
	}

	function copyShareURL() {
		// const str = document.getElementById('item-to-copy').innerText;
		const el = document.createElement("textarea");
		el.value = shareURL;
		el.setAttribute("readonly", "");
		el.style.position = "absolute";
		el.style.left = "-9999px";
		document.body.appendChild(el);
		el.select();
		document.execCommand("copy");
		document.body.removeChild(el);
		copiedShareUrl = true;
		if (copyTimeout) {
			clearTimeout(copyTimeout);
		}
		copyTimeout = setTimeout(() => {
			copiedShareUrl = false;
		}, 2500);
	}
	// function setFullImage(v) {
	// 	fullImage = v;
	// }
</script>

<style type="text/scss">
	/* your styles go here */
	.zenya-form-group {
		display: flex;

		label {
			flex: 1;
		}
	}
	#building-result-map {
		aspect-ratio: 21/9;
		margin-top: 2rem;
		border-radius: 4px;
		display: none;

		&.available {
			display: block;
		}
	}

	.details-container {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

		.details-layout {
			margin: 10px;

			.carousel-container {
				margin-top: 10px;
				border-radius: 4px;
				overflow: hidden;
			}

			.share-container {
				display: flex;
				flex-direction: row;
				align-items: center;

				.share-message {
					flex: 1;
					text-align: right;
					padding: 5px;
				}

				button.icon-button {
					border: 0 !important;
					background: none !important;
					height: 34px;
					width: 34px;
					border-radius: 16px;
					padding: 5px 6px 5px 3px;
					cursor: pointer;
				}
			}

			.update-request-section {
				// margin: 10px;
				padding: 10px;
				border: 1px solid lightgray;
				border-radius: 4px;

				h4 {
					margin: 0;
					margin-bottom: 10px;
				}

				.update-form {
					display: grid;
					grid-template-columns: 1fr 1fr auto;
					grid-template-rows: 2;
					grid-gap: 10px;
				}
			}

			.zenya-details {
				padding: 10px;
				margin: 10px 0;
				background: whitesmoke;
				border-radius: 4px;

				.zenya-details-button-row {
					display: flex;
					align-items: center;

					.zenya-details-price {
						font-weight: bolder;
						font-size: 1.3em;

						.zenya-price-suffix {
							font-size: 0.6em;
							color: darkgray;
							font-weight: normal;
							padding: 0 1em;
						}
					}
				}
				.zenya-details-additional-info {
					font-size: 0.9em;
					padding: 0.5em 1em;
					margin-top: 0.75em;
					padding-bottom: 0;
					border-top: 1px solid lightgray;
				}
			}

			.zenya-building-details,
			.zenya-room-details {
				margin: 0;
				margin-bottom: 15px;
				padding: 0.5em;
				border: 1px solid lightgray;
				border-radius: 4px;

				.accept-text {
					font-weight: bold;
					color: rgb(0, 80, 160);
				}

				.reject-text {
					font-weight: bold;
					color: red;
				}
				.details-building-name {
					font-size: 1.5em;
				}
				.details-building-address {
					color: gray;
				}

				.zenya-details-icons {
					& > div {
						display: inline-block;

						.zenya-details-icon {
							width: 40px;
							height: 40px;
							object-fit: cover;
						}
					}
				}

				.details-room-name {
					font-size: 1.2em;
				}
				.details-header {
					display: flex;
				}

				.details-row {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;

					.details-subheader {
						margin-bottom: 0.25em;
					}

					.details-description {
						/* padding: 1em; */
						flex: 2;
						min-width: 300px;
					}

					.details-amenities {
						/* padding: 1em; */
						min-width: 300px;
						flex: 1;
					}

					.details-additional-item {
						padding: 0.25em 0.5em;
						font-size: 0.9em;
					}
				}
			}
		}
	}

	.full-screen-button {
		position: absolute;
		top: 1em;
		right: 1em;
		background: rgba(255, 255, 255, 0.6);
		border-radius: 50%;
		height: 36px;
		width: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10;
		cursor: zoom-in;
	}

	.full-screen {
		background: rgba(0, 0, 0, 0.7);
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		display: block;
		box-sizing: border-box;
		z-index: 10;

		.image-container {
			position: absolute;
			top: 2em;
			right: 2em;
			bottom: 2em;
			left: 2em;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}

	.zenya-search-error {
		margin: -15px 10px 15px 10px;
		color: red;
		font-size: 0.9rem;
	}
</style>
