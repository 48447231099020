<svelte:head>
	{#if !customStyles}
		<link rel="stylesheet" href="{envURL}zenya_booking_custom.css" />
	{/if}
	<link rel="stylesheet" href="{envURL}zenya_booking_helpers.css" />
	<link rel="stylesheet" href="{envURL}zenya_booking_styles.css" />
</svelte:head>
<!-- <h5>widgetKey: {widgetKey}!</h5> -->

<div bind:this={scroller} id="zenya-scroll-target" />
<!-- <div class:hidden={$_loading == true}> -->

{#if errorMessage}
	<h3 class="zenya-error-message">
		{@html errorMessage}
	</h3>
{/if}
{#if ["search", "buildings", "units"].includes($route)}
	<SearchForm {formData} />
{/if}

{#if $route && !["search", "buildings", "units", "signagreement", "confirmation"].includes($route) && !isDirectLink}
	<a href="#" on:click|preventDefault={() => route.back()}>BACK</a>
{/if}
{#if $route == "buildings"}
	<ResultBuildings />
{:else if $route == "units"}
	<ResultTiles />
{:else if $route == "roomdetails"}
	<RoomDetails />
{:else if $route == "buildingdetails"}
	<BuildingDetailsComponent />
{:else if $route == "optionranking"}
	<OptionRanking />
{:else if $route == "contact"}
	<ContactForm />
{:else if $route == "booking"}
	<BookingComponent />
{:else if $route == "signagreement"}
	<SignAgreement />
{:else if $route == "confirmation"}
	<Confirmation />
{/if}
<!-- </div> -->
<!-- <div class:zenyahidden={$_loading == false} class="zenya-loading-mask"> -->
{#if $_loading}
	<div transition:fade={{ amount: 10 }} class="zenya-loading-mask">
		{#if defaultColor}
			<Stretch size="100" color={defaultColor} />
		{:else}
			<Stretch size="100" />
		{/if}
	</div>
{/if}

{#if env != "prod"}
	<div class="demo-indicator">DEMO</div>
{/if}

<!-- <SendLeaseComponent /> -->

<!-- <SignAgreement /> -->
<script>
	import { onMount, afterUpdate, setContext } from "svelte";
	import { fade } from "svelte/transition";

	import SearchForm from "./SearchForm.svelte";
	import ResultBuildings from "./ResultBuildings.svelte";
	import ResultTiles from "./ResultTiles.svelte";
	import ContactForm from "./ContactForm.svelte";
	import OptionRanking from "./OptionRanking.svelte";

	import { Stretch } from "svelte-loading-spinners";
	import dayjs from "dayjs";

	import "../public/global.scss";
	import "flatpickr/dist/flatpickr.css";
	// import "flatpickr/dist/themes/material_blue.css";

	import { buildings } from "./RoomStore.js";
	import { route, _loading } from "./RoutingStore";
	import BookingComponent from "./BookingComponent.svelte";
	import RoomDetails from "./RoomDetails.svelte";
	import SignAgreement from "./SignAgreement.svelte";
	import Confirmation from "./Confirmation.svelte";
	import { _config } from "./ConfigStore";
	import { requestInfo } from "./BookingStore";

	import { currentBuilding, currentRoom, options } from "./RoomStore";
	import BuildingDetailsComponent from "./BuildingDetailsComponent.svelte";
	// import SendLeaseComponent from "./SendLeaseComponent.svelte";

	export let widgetKey;
	export let env;
	export let customStyles;
	export let defaultLocation;

	let defaultColor;

	let scroller;

	let errorMessage;

	let isDirectLink = false;

	let formData;

	let postData = {
		url: "http://localhost:3030/api/v1/mapping/",
		// url: "https://ztyay8qot5.execute-api.us-east-2.amazonaws.com/TEST/",
		customerKey: widgetKey,
		options: {
			method: "POST",
			mode: "cors",
			cache: "no-cache",
			credentials: "omit", // include, *same-origin, omit
			headers: {
				"Content-Type": "application/json",
				"X-API-KEY": "ZkXyezfncP705ujfISWB03VRBKbBTFHE5X0TnXwP",
			},
			// redirect: 'follow', // manual, *follow, error
			referrerPolicy: "origin-when-cross-origin", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
		},
		fetchFunction: (route, data) => {
			let options = {
				...postData.options,
				headers: new Headers(postData.options.headers),
				body: JSON.stringify({
					data: { ...data },
					customerKey: postData.customerKey,
					route: route,
				}),
			};
			return fetch(postData.url, options);
		},
	};

	let envURL;
	if (env == "local") {
		envURL = "/build/";
	} else if (env == "demo") {
		envURL = "https://demo.zenya.io/booking/";
		postData.url = "https://demoappserver.zenya.io/api/v1/mapping/";
		// postData.url = "https://ztyay8qot5.execute-api.us-east-2.amazonaws.com/TEST/";

		// postData.options.headers["X-API-KEY"] = postData.customerKey;
		postData.fetchFunction = (route, data) => {
			return fetch(postData.url, {
				...postData.options,
				headers: new Headers(postData.options.headers),
				body: JSON.stringify({
					data: { ...data },
					customerKey: postData.customerKey,
					route,
				}),
			});
		};
	} else if (env == "prod") {
		envURL = "https://m.zenya.io/booking/";
		postData.url = "https://appserver.zenya.io/api/v1/mapping/";
		// postData.url = "https://ztyay8qot5.execute-api.us-east-2.amazonaws.com/Prod/";

		// postData.options.headers = {
		// 	"Content-Type": "application/json",
		// 	"X-API-KEY": "hgTfn0tvHXtG17F19z5v17NLMgaHWSY2Kve4nhi0",
		// };

		postData.fetchFunction = (route, data) => {
			return fetch(postData.url, {
				...postData.options,
				headers: new Headers(postData.options.headers),
				body: JSON.stringify({
					data: { ...data },
					customerKey: postData.customerKey,
					route,
				}),
			});
		};
	}

	setContext("fetch", postData.fetchFunction);
	setContext("envURL", envURL);

	onMount(async () => {
		updateDefaultColor();
		/// GET BOOKING WIDGET CONFIGURATION
		// _loading.set(true);
		let postResult = await postData.fetchFunction("getBookingConfig", {});
		let results = await postResult.json();
		if (results && results.data) {
			// _loading.set(false);
			results = results.data;
			// console.log(results);
			_config.set(results);
		}

		directLink();
		/// SCROLL TO TOP ON ROUTE CHANGE
		route.subscribe((res) => {
			if (res != "search") {
				scroller.scrollIntoView({
					block: "start",
					inline: "nearest",
					behavior: "smooth",
				});
			}
		});
	});

	function updateDefaultColor() {
		/// CREATE TEMPORARY BUTTON ELEMENT AND ADD CLASS
		let tempButton = document.createElement("button");
		tempButton.classList.add("zenya-booking-button");

		/// ADD BUTTON TO DOM
		document.querySelector("#zenya-booking-container").append(tempButton);
		/// GET STYLE OBJECT FOR BACKGROUND COLOR
		let computed = getComputedStyle(tempButton);
		defaultColor = computed.backgroundColor;
		/// REMOVE THE TEMPORARY ELEMENT
		tempButton.remove();

		/// CHECK IF THE COLOR HAS BEEN OVERRIDDEN
		if (defaultColor == "rgb(245, 245, 245)") {
			defaultColor = null;
		}
	}

	async function directLink() {
		/// HANDLE ROUTING FOR SPECIFIC ROOM LINK
		const params = new URLSearchParams(window.location.search);
		if (params.has("BookingCode")) {
			requestInfo.set({
				bookingCode: params.get("BookingCode"),
			});
		}
		if (params.has("unitId") && !$route) {
			isDirectLink = true;
			let unitId = params.get("unitId");

			// route.set("roomdetails");

			_loading.set(true);
			let postResult = await postData.fetchFunction("getRoomById", {
				roomId: unitId,
				directLink: true,
			});
			_loading.set(false);
			if (postResult.ok) {
				let results = await postResult.json();
				errorMessage = null;
				if (results && results.data) {
					results = results.data;
					if (
						results.buildings &&
						results.buildings.length &&
						results.buildings[0].rooms &&
						results.buildings[0].rooms.length
					) {
						currentBuilding.set(results.buildings[0]);
						currentRoom.set(results.buildings[0].rooms[0]);
						route.set("roomdetails");
					} else {
						console.error("No results");
					}
				}
			} else {
				let results = await postResult.json();
				let link = window.location.origin + window.location.pathname;
				errorMessage = `This link is invalid. Click <a href="${link}">here</a> to search.`;
				console.error(results);
				// if (results && results.data) {
				// 	results = results.data;
				// 	console.error(results);
				// }
			}
		} else if (params.has("buildingId") && !$route) {
			isDirectLink = true;
			let buildingId = params.get("buildingId");

			_loading.set(true);
			let postResult = await postData.fetchFunction(
				"getRoomsByBuildingId",
				{
					buildingId: buildingId,
				},
			);
			_loading.set(false);
			if (postResult.ok) {
				let results = await postResult.json();
				errorMessage = null;
				if (results && results.data) {
					results = results.data;
					if (
						results.buildings &&
						results.buildings.length &&
						results.buildings[0].rooms &&
						results.buildings[0].rooms.length
					) {
						currentBuilding.set(results.buildings[0]);
						// currentRoom.set(results.buildings[0].rooms);
						route.set("buildingdetails");
					} else {
						console.error("No results");
					}
				}
			} else {
				let results = await postResult.json();
				let link = window.location.origin + window.location.pathname;
				errorMessage = `This link is invalid. Click <a href="${link}">here</a> to search.`;
				console.error(results);
				// if (results && results.data) {
				// 	results = results.data;
				// 	console.error(results);
				// }
			}
		} else if (params.has("optionId") || params.has("optionid")) {
			isDirectLink = true;
			let optionId = params.get("optionId") || params.get("optionid");

			_loading.set(true);
			let postResult = await postData.fetchFunction("getRoomById", {
				optionId: optionId,
				directLink: true,
			});
			_loading.set(false);
			if (postResult.ok) {
				let results = await postResult.json();
				errorMessage = null;
				if (results && results.data) {
					results = results.data;
					if (
						results.buildings &&
						results.buildings.length &&
						results.buildings[0].rooms &&
						results.buildings[0].rooms.length
					) {
						currentBuilding.set(results.buildings[0]);
						currentRoom.set(results.buildings[0].rooms[0]);
						route.set("roomdetails");
					} else {
						console.error("No results");
					}
				}
			} else {
				let results = await postResult.json();
				// let link = window.location.origin + window.location.pathname;
				errorMessage = `This link expired.`;
				console.error(results);
				// if (results && results.data) {
				// 	results = results.data;
				// 	console.error(results);
				// }
			}
		} else if (params.has("optionsList")) {
			let shortcode =
				params.get("optionsList") || params.get("optionsList");

			_loading.set(true);
			let postResult = await postData.fetchFunction(
				"getDealsListDetails",
				{
					shortcode,
				},
			);
			_loading.set(false);
			if (postResult.ok) {
				let results = await postResult.json();
				errorMessage = null;
				if (results && results.data) {
					results = results.data;
					if (results && results.deals && results.deals.length) {
						options.set(results);
						route.set("optionranking");
					} else {
						console.error("No results");
						errorMessage = `This link expired.`;
					}
				}
			} else {
				let results = await postResult.json();
				errorMessage = `This link expired.`;
				console.error(results);
			}
		} else if ((params.has("loc") || defaultLocation) && !$route) {
			_config.subscribe(async (res) => {
				/// SET FULL LATLONG STRING BASED ON SCRIPT ATTRIBUTE OR URL PARAM
				let fullLoc = defaultLocation
					? defaultLocation
					: params.get("loc");
				/// SPLIT LATLONG STRING INTO ARRAY
				let loc = fullLoc.split(",");

				/// GET CITY AND STATE FROM LATLONG
				/// REVERSE GEOCODE LATLONG TO GET LOCATION LIST
				let locationName;
				let reverseGeocode = await fetch(
					`https://maps.googleapis.com/maps/api/geocode/json?latlng=${fullLoc}2&key=AIzaSyBSPMHKcKRXM27KGZX53-Z_lhqDRVC3FVs`,
				);
				let rgResults = await reverseGeocode.json();
				/// ONE RESULTS WILL BE CITY + STATE (OTHERES WILL BE MORE OR LESS PRECICE)
				if (
					rgResults &&
					rgResults.results &&
					rgResults.results.length > 0
				) {
					let cityState = rgResults.results.find(
						(g) =>
							g.types.length == 2 &&
							g.types.includes("locality") &&
							g.types.includes("political"),
					);
					/// GET formatted_address FROM MATCHING RESULT (IF AVAILABLE);
					locationName = cityState ? cityState.formatted_address : "";
				}

				/// SETUP DEFAULT DATE RANGE BASED ON MIN STAY AND BOOKING WINDOW
				let start = dayjs();
				if (res.minBookingWindow) {
					start = start.add(res.minBookingWindow, "d");
				}
				let end = start.add(1, "d");
				if (res.minStayLength) {
					end = start.add(res.minStayLength, "d");
				}

				formData = {
					location: { lat: +loc[0], long: +loc[1] },
					distance: "20",
					startDate: start.hour(12).toDate(),
					endDate: end.hour(12).toDate(),
					bookingCode: params.get("BookingCode"),
					locationName: locationName,
				};

				_loading.set(true);
				let postResult = await postData.fetchFunction(
					"getRoomsByLocations",
					formData,
				);

				_loading.set(false);
				if (postResult.ok) {
					let results = await postResult.json();
					errorMessage = null;
					if (results && results.data) {
						let result = results.data;

						requestInfo.set({
							...formData,
							validCode: result.validCode,
							_company: result.company,
						});

						buildings.set(result.buildings);

						route.set("buildings");
					}
				} else {
					let results = await postResult.json();
					let link =
						window.location.origin + window.location.pathname;
					errorMessage = `This link is invalid.`;
					if (!defaultLocation) {
						errorMessage += `Click <a href="${link}">here</a> to search.`;
						console.error(results);
					}

					// if (results && results.data) {
					// 	results = results.data;
					// 	console.error(results);
					// }
				}
			});
		} else {
			route.set("search");
		}
	}
</script>

<style type="text/scss">
	.zenya-error-message {
		text-align: center;
	}
	.zenyahidden {
		display: none !important;
	}
	.zenya-loading-mask {
		position: fixed;
		left: 0;
		top: 0;
		height: 100vh;
		width: 100vw;
		background: rgba(0, 0, 0, 0.6);
		background: rgba(255, 255, 255, 0.6);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 100;
	}

	.demo-indicator {
		// width: 100%;
		padding: 12px 16px;
		border-radius: 4px;
		border-style: solid;
		border-width: 1px;
		margin: 12px;
		font-size: 16px;
		font-weight: bolder;
		text-align: center;

		background-color: rgba(252, 248, 227, 1);
		border-color: rgba(177, 161, 129, 1);
		color: rgba(138, 109, 59, 1);
	}
</style>
