<div class="booking-layout">
	<div class="booking-details">
		<h2>Thank You!</h2>
		{#if $bookingInfo.sentLeaseAgreement && $_config.altConfirmationMessage}
			<h4>{$_config.altConfirmationMessage}</h4>
		{:else if $bookingInfo.backgroundCheckPending && $_config.confirmationMessagePendingBGCheck}
			<h4>{$_config.confirmationMessageBGCheckPending}</h4>
		{:else if $_config.confirmationMessage}
			<h4>{$_config.confirmationMessage}</h4>
		{:else}
			<h4>You have successfully booked your room!</h4>
		{/if}

		<ul>
			<li>{$bookingInfo.roomType} @ {$bookingInfo.buildingName}</li>
			<li>{$bookingInfo.address}</li>
			<li>
				{$_config.checkinAlt}:
				{dayjs($bookingInfo.checkIn).format("dddd, MMMM D, YYYY")}
			</li>
			<li>
				{$_config.checkoutAlt}:
				{dayjs($bookingInfo.checkOut).format("dddd, MMMM D, YYYY")}
			</li>
			<li>
				Number of
				{$bookingInfo.timeframe}s:
				{$bookingInfo.duration}
			</li>
			<li>
				Cost per
				{$bookingInfo.timeframe}:
				{currency($bookingInfo.rent.price).format()}
			</li>
		</ul>

		<h3>Total: {currency($bookingInfo.total).format()}</h3>
		{#if $bookingInfo.paymentResults && $bookingInfo.paymentResults.length}
			{#if $bookingInfo.paymentResults.length == 1}
				<h4>View your invoice</h4>
			{:else}
				<h4>View your invoices</h4>
			{/if}
			{#each $bookingInfo.paymentResults as receipt}
				<div>
					{#if receipt.paymentReceiptPdf}
						<a
							href={receipt.paymentReceiptPdf}
							download
							target="_blank">Invoice for {receipt.payorName}</a
						>
					{:else if receipt.paymentType}
						{receipt.payorName}: {receipt.paymentType}
					{/if}
				</div>
			{/each}
		{/if}
		{#if $_config.agreementDocument && $bookingInfo.tempUrl}
			<h4>
				Download a copy of your signed
				{$_config.agreementDocument.documentName}
				<a href={$bookingInfo.tempUrl} download target="_blank">here</a>
				<!-- <a
					href="#"
					on:click|preventDefault={() =>
						download(
							$bookingInfo.tempUrl,
							$bookingInfo.documentFile
						)}>here</a 
				>-->
			</h4>
		{/if}
	</div>
</div>

<script>
	import { bookingInfo } from "./BookingStore";
	import { _config } from "./ConfigStore";
	import dayjs from "dayjs";
	import currency from "currency.js";

	// const download = (url, name) => {
	// 	if (!url) {
	// 		throw new Error(
	// 			"Resource URL not provided! You need to provide one"
	// 		);
	// 	}
	// 	fetch(url)
	// 		.then((response) => {
	// 			console.log(response);

	// 			return response.blob();
	// 		})
	// 		.then((blob) => {
	// 			const blobURL = URL.createObjectURL(blob);
	// 			const a = document.createElement("a");
	// 			a.href = blobURL;
	// 			a.style = "display: none";

	// 			if (name && name.length) {
	// 				a.download = name;
	// 			}
	// 			document.body.appendChild(a);
	// 			a.click();
	// 		})
	// 		.catch(() => {
	// 			console.warn("Error saving document");
	// 		});
	// };
</script>

<style type="text/scss">
	/* your styles go here */
	.booking-layout {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.booking-details {
			flex: 1;
		}
		.booking-form {
			flex: 2;

			.zenya-form-group {
				display: flex;

				label {
					flex: 1;
					margin: 5px;
				}
			}
		}
	}
</style>
