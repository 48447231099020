<div class="zenya-room-result">
	<div class="zenya-room-result-content">
		{#if building.buildingHideRoomType != "Yes"}
			<div class="zenya-tile-title">
				{room.roomType}
			</div>
		{/if}
		<div class="zenya-tile-details">
			<!-- ${room.price}/night -->

			{#if displayPrice(room.price, room.currentPrice)}
				<div class="zenya-tile-details-price">
					{#if $_config.rateDisplay == "Original Rate Slashed Out" && room.originalPrice && room.originalPrice != room.price}
						<span class="zenya-slashed-price"
							>{currency(room.originalPrice).format()}</span
						>
					{/if}{currency(room.price).format()}/{room.timeframe}
					{#if $_config.priceSuffix}
						<div class="zenya-price-suffix">
							{$_config.priceSuffix}
						</div>
					{/if}
				</div>
			{/if}
		</div>
	</div>
	<div class="zenya-tile-actions">
		{#if $_config.displayBuildingDetailsButton != "Hide"}
			<button
				class="zenya-booking-button zenya-secondary-button zenya-base-button zenya-room-result-details"
				on:click={roomDetails(room, building)}>Details</button
			>
		{/if}
		{#if building.buildingDynamicPricing && !room.currentPrice}
			<button
				class="zenya-booking-button zenya-room-result-book"
				on:click={checkPrice(room, building)}
				id="results-check"
			>
				Check Price</button
			>
		{:else if room.price && !room.requestOnly && ($_config.showRates == "Always" || ($_config.showRates == "Booking Code Entered" && $requestInfo.bookingCode))}
			<button
				class="zenya-booking-button zenya-room-result-book"
				on:click={bookRoom(room, building)}
				id="results-book">Book</button
			>
			{#if $_config.requestButtonAlternative && $requestInfo._company && $requestInfo._company.temporaryHoldButton == 1}
				<button
					class="zenya-booking-button zenya-room-result-request"
					on:click={contact(room, building)}
					id="results-hold"
					>{$_config.requestButtonAlternative}</button
				>
			{/if}
		{:else}
			<button
				class="zenya-booking-button zenya-room-result-request"
				on:click={contact(room, building)}
				id="results-contact"
			>
				{$_config.requestButtonAlternative &&
				$requestInfo &&
				$requestInfo._company &&
				$requestInfo._company.temporaryHoldButton == 1
					? $_config.requestButtonAlternative
					: "Request"}
			</button>
		{/if}
	</div>

	{#if loading}
		<div class="zenya-loading-cover">
			<Pulse size="45" color={"#888"} />
		</div>
	{/if}
</div>
<UnavailableComponent bind:this={unavailable} />

<script>
	import { getContext } from "svelte";
	import { route, _loading } from "./RoutingStore";
	import { buildings, currentBuilding, currentRoom } from "./RoomStore";
	import { _config } from "./ConfigStore";
	import { requestInfo, blockedReservation } from "./BookingStore";
	import dayjs from "dayjs";
	import currency from "currency.js";
	import UnavailableComponent from "./UnavailableComponent.svelte";

	import { Pulse } from "svelte-loading-spinners";

	let unavailable;

	let post = getContext("fetch");

	export let building;
	export let room;

	let loading = false;

	async function roomDetails(e, building) {
		// currentBuilding.set({ ...building });
		// currentRoom.set({ ...e });
		// route.set("roomdetails");

		_loading.set(true);
		let postResult = await post("getRoomById", {
			roomId: e.roomId,
			startDate: $requestInfo ? $requestInfo.startDate : null,
			endDate: $requestInfo ? $requestInfo.endDate : null,
			bookingCode: $requestInfo ? $requestInfo.bookingCode : null,
		});
		_loading.set(false);
		if (postResult.ok) {
			let results = await postResult.json();
			// errorMessage = null;
			if (results && results.data) {
				results = results.data;
				if (
					results.buildings &&
					results.buildings.length &&
					results.buildings[0].rooms &&
					results.buildings[0].rooms.length
				) {
					/// SET INFO AND ROUTE TO BOOKING FORM
					currentBuilding.set(results.buildings[0]);
					currentRoom.set(results.buildings[0].rooms[0]);
					route.set("roomdetails");
				} else {
					console.error("No results");
				}
			}
		} else {
			let results = await postResult.json();
			// let link = window.location.origin + window.location.pathname;
			// errorMessage = `This link is invalid. Click <a href="${link}">here</a> to search.`;
			console.error(results);
			// if (results && results.data) {
			// 	results = results.data;
			// 	console.error(results);
			// }
		}
	}
	async function bookRoom(room, building) {
		currentBuilding.set({ ...building });
		let nights =
			Math.round(
				dayjs($requestInfo.endDate).diff(
					$requestInfo.startDate,
					"days",
					true,
				),
			) + 1;

		_loading.set(true);
		let postResult = await post("blockReservation", {
			checkIn: $requestInfo.startDate,
			checkOut: $requestInfo.endDate,
			numberNights: nights,
			roomId: room.roomId,
			roomNumber: room.name,
		});
		_loading.set(false);

		let result = await postResult.json();

		// if (postResult.ok) {
		if (postResult.ok && result.data.available == true) {
			// console.log(result);

			/// SAVE GUEST ID
			blockedReservation.set(result.data);

			/// GET MORE ROOM DETAILS
			_loading.set(true);
			let postResult = await post("getRoomById", {
				roomId: room.roomId,
				startDate: $requestInfo.startDate,
				endDate: $requestInfo.endDate,
				bookingCode: $requestInfo.bookingCode,
			});
			_loading.set(false);
			if (postResult.ok) {
				let results = await postResult.json();
				// errorMessage = null;
				if (results && results.data) {
					results = results.data;
					if (
						results.buildings &&
						results.buildings.length &&
						results.buildings[0].rooms &&
						results.buildings[0].rooms.length
					) {
						/// SET INFO AND ROUTE TO BOOKING FORM
						currentBuilding.set(results.buildings[0]);
						currentRoom.set({
							...results.buildings[0].rooms[0],
							rate: room.rate,
							additionalFees: room.additionalFees,
						});
						route.set("booking");
					} else {
						console.error("No results");
					}
				}
			} else {
				let results = await postResult.json();
				console.error(results);
			}
		} else if (postResult.ok && result.data.available == false) {
			unavailable.setModalState(true);
			console.warn(
				"This unit is no longer available. Please try another",
			);
		} else {
			console.error(result);
		}
	}

	function contact(e, building) {
		currentBuilding.set({ ...building });
		currentRoom.set({ ...e });
		route.set("contact");
	}

	async function checkPrice() {
		loading = true;
		let postResult = await post("dynamicPricing", {
			roomId: room.roomId,
			startDate: $requestInfo.startDate,
			endDate: $requestInfo.endDate,
		});
		loading = false;
		let result = await postResult.json();

		if (postResult.ok && result.data.result == "success") {
			room.price = result.data.price;
			room.timeframe = result.data.timeframe;

			room.rate.price = result.data.price;

			room.currentPrice = true;
			console.log(result);
		} else {
			console.error(result);
		}
	}

	function displayPrice(price, currentPrice) {
		if (building.buildingDynamicPricing) {
			return price && currentPrice;
		} else {
			return (
				price &&
				($_config.showRates == "Always" ||
					($_config.showRates == "Booking Code Entered" &&
						$requestInfo.bookingCode))
			);
		}
	}
</script>

<style type="text/scss">
	// @import "microtip/microtip";

	// [role~="tooltip"][data-microtip-position|="top"]::before {
	// 	margin-bottom: -5px;
	// }
	// [role~="tooltip"][data-microtip-position|="top"]::after {
	// 	margin-bottom: 1px;
	// }

	.zenya-result-grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
		grid-gap: 1rem;
	}

	.zenya-stay-length-alert {
		padding: 0.25em 0.5em 0.4em 0.5em;
		margin: 0.5em 0 0.25em 0;
		text-align: center;
		font-size: 0.9em;

		border: 1px solid rgb(188, 232, 241);
		border-radius: 4px;
		background-color: rgb(217, 237, 247);
		color: rgb(58, 135, 173);
	}

	.zenya-room-result {
		// background: whitesmoke;
		// border: 1px solid lightgray;
		// border-radius: 4px;
		// padding: 6px;
		// margin-bottom: 10px;
		// font-size: 0.7em;
		// display: flex;

		// background: whitesmoke;
		/* border: 1px solid lightgray; */
		/* border-radius: 4px; */
		border-top: 1px solid lightgray;
		padding: 0 6px;
		padding-top: 10px;
		margin-bottom: 12px;
		font-size: 0.7em;
		display: flex;
		flex-direction: column;
		position: relative;

		.zenya-loading-cover {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			background: rgba(255, 255, 255, 0.7);
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 99;
		}

		.zenya-room-result-content {
			flex: 1;

			.zenya-tile-title {
				font-weight: 500 !important;
				font-size: 1.2em !important;
			}

			.zenya-tile-details-price {
				// display: flex;
				// flex: 1;
				// align-items: baseline;

				font-size: 1.3em;
				text-align: left;

				.zenya-price-suffix {
					// flex: 1;
					// text-align: end;
					display: inline;
				}
			}
		}

		.zenya-tile-actions {
			margin-top: 0.5em;
			min-height: 2.5em;

			display: grid;
			// grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
			grid-column-gap: 7px;
			grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));

			button.zenya-booking-button {
				font-size: 1em;
				padding: 6px 10px !important;
				margin-top: 0 !important;
			}
		}
	}
</style>
