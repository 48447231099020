<div class="booking-addon-item" class:zenya-fee-error={fee.error}>
	{#if fee.description}
		<div class="booking-addon-description">
			{fee.description}
		</div>
	{/if}
	{#if fee.optional}
		<input
			type="checkbox"
			bind:checked={fee.checked}
			on:change={updatePaymentGrid}
		/>
	{/if}
	{fee.ratetypename}:
	{currency(fee.price).format()}

	{#if fee.pricetypename != "Full"}
		{fee.pricetypename}
	{/if}
	({currency(fee.subtotal).format()})

	{#if fee.rates && fee.rates.length > 1}
		<span class="zenya-multiple-rates-container">
			<a
				href="#"
				class="zenya-multiple-rates-toggle"
				on:click|preventDefault={toggleRateDetails}
				>Details
			</a>
			<div
				class="zenya-multiple-rates"
				class:zenya-multiple-rates-show={showRateDetails}
			>
				Averaged From: {#each fee.rates as subRate}
					<div>
						{subRate.days} days @ {currency(subRate.price).format()}
						| {dayjs(subRate.startdate).format("MM/DD/YYYY")} to {dayjs(
							subRate.enddate
						).format("MM/DD/YYYY")}
					</div>
				{/each}
			</div></span
		>
	{/if}

	{#if (fee.checked || !fee.optional) && !estimator}
		<!-- {#if assignees.length > 2} -->
		<div class="zenya-assignee-grid">
			<div class="zenya-filter-assignee-payor">
				<div class="zenya-assignee-setup">
					<div class="zenya-assignee-cell">Assign Payments to</div>
					<div
						class="zenya-assignee-cell"
						class:zenya-single-item={fee.filteredAssignees.length <
							2}
					>
						<Select
							items={[...$assignees]}
							bind:selectedValue={fee.filteredAssignees}
							placeholder="Assign payments to"
							optionIdentifier="assigneeId"
							isMulti="true"
							getSelectionLabel={paidByLabel}
							getOptionLabel={paidByLabel}
							on:select={(e) => updatePaymentGrid(e.detail)}
							isClearable={false}
							containerStyles="flex: 1;"
						/>
						<!-- {fee.filteredAssignees.length}
						{JSON.stringify(fee.filteredAssignees)} -->
					</div>
				</div>
				<div class="zenya-assignee-setup">
					<div class="zenya-assignee-cell">Paid By:</div>
					<div
						class="zenya-assignee-cell"
						class:zenya-single-item={fee.filteredPayors.length < 2}
					>
						<button
							type="button"
							class="zenya-add-payor"
							on:click={() => addPayor()}>+</button
						>
						<Select
							items={[...$payors]}
							bind:selectedValue={fee.filteredPayors}
							placeholder="Paid by"
							optionIdentifier="payorId"
							isMulti="true"
							getSelectionLabel={paidByLabel}
							getOptionLabel={paidByLabel}
							containerStyles="flex: 1;"
							on:select={(e) => updatePaymentGrid(e.detail)}
							isClearable={false}
						/>
					</div>
				</div>
			</div>
			{#if fee.assignees && fee.assignees.length && ((fee.filteredAssignees && fee.filteredAssignees.length > 1) || (fee.filteredPayors && fee.filteredPayors.length > 1))}
				<div class="zenya-assignee-row zenya-assignee-description">
					<div class="zenya-assignee-cell">Assign Payments to</div>
					<div class="zenya-assignee-cell">Paid by</div>
				</div>
				{#each fee.assignees as assignee}
					{#if fee.filteredAssignees
						.map((a) => a.assigneeId)
						.includes(assignee.assigneeId)}
						<div class="zenya-assignee-row">
							<div class="zenya-assignee-cell">
								{paidByLabel(assignee)}
							</div>
							<div class="zenya-assignee-cell">
								{#each assignee.payors as payor}
									<div>
										<!-- {JSON.stringify(payor)} -->
										{paidByLabel(payor)} - {currency(
											payor.amount || 0
										).format()} ({payor.percent[0] || 0}%)
										<RangeSlider
											min={0}
											max={100}
											step={1}
											float
											suffix="%"
											bind:values={payor.percent}
											on:change={(e) =>
												updatePayAmountByPercent(
													payor,
													e
												)}
										/>
									</div>
								{/each}
							</div>
						</div>
					{/if}
				{/each}
			{/if}
			{#if fee.error}
				<div class="zenya-fee-errors">
					{fee.error}
				</div>
			{/if}
		</div>
	{/if}

	<!-- <pre>{JSON.stringify(fee)}</pre> -->
</div>

<script>
	import { onMount } from "svelte";

	import Select from "svelte-select";
	import RangeSlider from "svelte-range-slider-pips";

	import currency from "currency.js";
	import dayjs from "dayjs";

	import { guests, assignees, payors, allFees } from "./BookingStore";

	/// COMPONENT INPUTS
	export let fee;

	export let estimator;

	export let bookingGuests;

	/// COMPONENT VARIABLES
	let addingPayor;
	let showRateDetails = false;

	onMount(async () => {
		/// SET FEES DEFAULT ASSIGNEES AND PAYORS WHEN FIRST GUEST IS ADDED
		if ($guests.length == 1) {
			initializeAssigneesAndPayors();
		} else {
			guests.subscribe(() => {
				initializeAssigneesAndPayors();
			});
		}
		payors.subscribe(() => {
			/// SET FEES SELECTED PAYORS AFTER ADDING VIA + BUTTON
			if (addingPayor) {
				let addedPayor = [...$payors].pop();
				if (fee.filteredPayors.length > 1) {
					fee.filteredPayors = [...fee.filteredPayors, addedPayor];
				} else {
					fee.filteredPayors = [addedPayor];
				}
				addingPayor = false;
			}
		});
	});

	function initializeAssigneesAndPayors() {
		if (
			$assignees.length &&
			(!fee.filteredAssignees || fee.filteredAssignees.length < 1)
		) {
			fee.filteredAssignees = [...$assignees];
		}
		if (
			$payors.length &&
			(!fee.filteredPayors || fee.filteredPayors.length < 1)
		) {
			fee.filteredPayors = [...$payors];
		}
		fee = { ...fee };
	}

	async function updatePaymentGrid() {
		/// UPDATE FEE WITH LIST OF SELECTED ASSIGNEES AND PAYORS

		let activeAssignees = [],
			activePayors = [];
		if (fee.filteredAssignees) {
			activeAssignees = fee.filteredAssignees.map((a) => a.assigneeId);
		}
		if (fee.filteredPayors) {
			activePayors = fee.filteredPayors.map((p) => p.payorId);
		}
		fee.assignees = $assignees
			.filter((a) => activeAssignees.includes(a.assigneeId))
			.map((a) => {
				a.payors = $payors
					.filter((p) => activePayors.includes(p.payorId))
					.map((p) => {
						if (
							activeAssignees.length == 1 &&
							activePayors.length == 1
						) {
							/// ONLY 1 PAYOR SO THEY PAY ALL
							p.amount = fee.price;
							p.percent = [100];
						} else {
							/// FIND IF THE PAYOR ALREADY HAS A VALUE SET
							let currentAssignee = fee.assignees
								? fee.assignees.find(
										(fa) => fa.assigneeId == a.assigneeId
								  )
								: null;
							let currentPayor =
								currentAssignee && currentAssignee.payors
									? currentAssignee.payors.find(
											(fp) => fp.payorId == p.payorId
									  )
									: null;

							if (currentPayor) {
								/// PAYOR WAS FOUND, SO SET PREVIOUS VALUES
								p.amount = currentPayor.amount;
								p.percent = currentPayor.percent;
							} else {
								/// PAYOR WAS NOT FOUND, SO SET TO 0
								p.amount = 0;
								p.percent = [0];
							}
						}

						return { ...p, assignedToId: a.assigneeId };
					});
				return { ...a };
			});
		fee = { ...fee };
		checkFeePayments();
	}

	function updatePayAmountByPercent(payor, percent) {
		let allPayors = fee.assignees.flatMap((a) => a.payors);
		if (allPayors.length == 2) {
			let otherPayor = allPayors.find(
				(p) =>
					p.payorId != payor.payorId ||
					p.assignedToId != payor.assignedToId
			);

			if (otherPayor) {
				otherPayor.percent = [100 - percent.detail.value];
				otherPayor.amount = fee.price * (otherPayor.percent[0] / 100);
			}
		}

		payor = {
			...payor,
			// percent: percent.value,
			amount: fee.price * (percent.detail.value / 100),
		};

		checkFeePayments();
	}

	function checkFeePayments() {
		let payorPercentages = fee.assignees
			.flatMap((a) => a.payors)
			.map((p) => p.percent[0]);
		let totalPercent = payorPercentages.reduce(
			(t, p) => t + parseInt(p),
			0
		);
		let error;
		if (totalPercent < 100) {
			error = `Full payment has not been set. Add ${
				100 - totalPercent
			}% to correct the amount`;
		} else if (totalPercent > 100) {
			error = `Payments set too high. Remove ${
				totalPercent - 100
			}% to correct the amount`;
		}
		if (!estimator) {
			fee.error = error;
		}

		let tempFees = [...$allFees];
		tempFees.splice(
			tempFees.findIndex((t) => t.rateId == fee.rateId),
			1,
			{ ...fee }
		);
		allFees.set([...tempFees]);
	}

	function addPayor() {
		addingPayor = true;
		bookingGuests.openStripe({
			presetType: true,
			onlyPayor: true,
		});
	}

	function paidByLabel(o) {
		return (
			o.firstName +
			" " +
			(o.lastName || "") +
			(o.ccType ? " (" + o.ccType + ")" : "")
		);
	}

	function toggleRateDetails() {
		showRateDetails = !showRateDetails;
	}
</script>

<style lang="scss">
	.booking-addon-item {
		padding: 5px 0px;
		// border-bottom: 1px solid darkgray;
		position: relative;

		&:not(:last-child) {
			border-bottom: 1px solid darkgray;
		}

		&.zenya-fee-error {
			background: rgba(255, 0, 0, 0.2);
		}

		& > input[type="checkbox"] {
			position: absolute;
			left: 5px;
		}
		.booking-addon-description {
			font-size: 0.9em;
			/* margin-left: 2em; */
		}

		.zenya-assignee-grid {
			padding: 0 2em;
			.zenya-filter-assignee-payor {
				.zenya-assignee-setup {
					display: grid;
					align-items: center;
					grid-template-columns: 1fr 3fr;
					column-gap: 10px;

					--multiItemMargin: 5px 5px 5px 0px;
					--multiItemHeight: 24px;
					--multiItemLineHeight: 24px;
					--multiClearTop: 3px;

					.zenya-assignee-cell {
						display: flex;
						align-items: center;
						flex-direction: row;
						align-content: center;

						button.zenya-add-payor {
							width: 30px;
							height: 30px;
							border-radius: 50%;
							border: 1px solid rgba(0, 0, 0, 0.4);
							background: white;
							margin-right: 5px;
						}

						*:not(button) {
							flex: 1;
						}
					}
				}
			}

			.zenya-assignee-row {
				display: grid;
				align-items: center;
				grid-template-columns: 1fr 3fr;
				column-gap: 10px;
				transition: background 0.4s ease;
				padding: 4px 6px;
				border-radius: 4px;
				font-size: 14px;
				font-weight: bold;

				&:hover {
					background: rgba(0, 0, 0, 0.2);
				}

				&.zenya-assignee-description {
					border-top: 2px solid lightgray;
					padding-top: 5px;
					margin-top: 5px;
				}

				.zenya-assignee-cell {
					display: grid;
					grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
					grid-gap: 12px;

					input[type="number"] {
						background: white !important;
						border: 1px solid !important;
						border-color: #d8dbdf !important;
						border-radius: 4px;
						height: 24px !important;
					}
				}
			}
		}
	}

	.zenya-single-item
		:global(.selectContainer .multiSelectItem .multiSelectItem_clear) {
		display: none;
	}

	.zenya-multiple-rates-container {
		position: relative;

		.zenya-multiple-rates {
			display: none;
			border-radius: 4px;

			background-color: white;
			box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
				0px 2px 2px 0px rgba(0, 0, 0, 0.14),
				0px 1px 5px 0px rgba(0, 0, 0, 0.12);
			position: absolute;
			padding: 0.5em 1em;
			font-size: 0.9em;
			z-index: 100;
			bottom: calc(100% - 1.2em);
			left: 105%;
			width: max-content;

			&.zenya-multiple-rates-show {
				display: block;
			}
		}
	}
</style>
