{#if modalState}
	<div class="zenya-unavailable-modal-wrapper" transition:fade>
		<div class="zenya-unavailable-modal-container">
			<div class="zenya-unavailable-modal-content">
				<div class="zenya-unavailable-modal-section">
					<div class="zenya-unavailable-modal-section-subheader">
						Unavailable
					</div>
					<div>
						{$_config.unavilableMessage ||
							"This unit is not avilable. Please select another or contact us directly for more assistance."}
					</div>

					<button
						type="button"
						class="zenya-booking-button zenya-search-contact"
						on:click={() => route.set("contact")}>Contact Us</button
					>
				</div>
			</div>
			<div class="zenya-lease-modal-actions">
				<button
					on:click={() => setModalState(false)}
					class="zenya-booking-button zenya-cancel-button"
					>Cancel</button
				>
				<div class="zenya-flex-spacer" />
				<!-- <button
					class="zenya-booking-button zenya-send-lease-popup-send"
					on:click={sendMessage}>Send</button
				> -->
			</div>
		</div>
	</div>
{/if}

<script>
	import { fade } from "svelte/transition";
	// import { afterUpdate, getContext } from "svelte";
	import { _loading, route } from "./RoutingStore";
	import { _config } from "./ConfigStore";
	// import { bookingInfo } from "./BookingStore";

	let modalState = false;
	export const setModalState = (state) => {
		modalState = state;
	};
</script>

<style lang="scss">
	.zenya-unavailable-modal-wrapper {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: rgba(255, 255, 255, 0.6);
		background: rgba(0, 0, 0, 0.4);
		display: flex;
		align-items: center;
		justify-content: center;
		backdrop-filter: blur(2px);
		z-index: 10;

		.zenya-unavailable-modal-container {
			padding: 10px;
			background-color: white;
			border-radius: 4px;
			box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.7);
			min-width: 50vw;
			min-height: 50vh;
			max-width: 90vw;
			max-height: 90vh;
			display: flex;
			flex-direction: column;

			.zenya-unavailable-modal-content {
				flex: 1;
				overflow-y: auto;

				.zenya-unavailable-modal-section {
					// border: 1px solid lightgray;
					padding: 10px;
					border-radius: 4px;
					margin-bottom: 15px;

					.zenya-unavailable-modal-section-subheader {
						font-weight: bolder;
						font-size: 1.2em;
						margin-bottom: 0.5em;
					}

					label {
						margin-bottom: 10px;
					}
				}
			}
			.zenya-unavailable-modal-actions {
				margin-top: 10px;
				display: flex;
				flex-direction: row;

				.zenya-flex-spacer {
					flex: 1;
				}

				.zenya-cancel-button {
					background: white !important;
					color: red !important;
					border: 1px solid red !important;
					border-radius: 4px;
				}
			}
		}
	}
</style>
