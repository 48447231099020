<form class="zenya-search-form" on:submit|preventDefault={getSearchResults}>
	{#if $_config.searchText && $_config.searchText.length}
		<div class="zenya-search-text-container">
			<div id="zenya-search-text">
				{@html $_config.searchText}
			</div>
		</div>
	{/if}
	<div class="zenya-search-container">
		<label>
			Location
			<GooglePlacesAutocomplete
				apiKey={gApiKey}
				class="form-control"
				on:place_changed={onPlaceChanged}
				placeholder=""
			/>
		</label>
		<div class="zenya-form-group">
			<!-- <label class="selectContainer">
				Location
				<Select
					items={locations}
					placeholder=""
					bind:selectedValue={formData.location}
					groupBy={locationGroups}
				/>
			</label> 
			<label>
				{$_config.roomAlt}
				Type
				<Select
					items={roomTypes}
					bind:selectedValue={formData.roomTypeId}
					placeholder=""
					optionIdentifier="value"
				/>
			</label>-->
			<label class="selectContainer">
				Distance
				<!-- 
				<Select
					items={[{label}]}
					placeholder=""
					bind:selectedValue={formData.distance}
				/> -->

				<select bind:value={formData.distance}>
					<option value="10">10mi</option>
					<option value="20">20mi</option>
					<option value="50">50mi</option>
					<option value="100">100mi</option>
				</select>
			</label>
			{#if flatpickrOptions && flatpickrOptions.mode}
				<!-- content here -->
				<label class="zenya-custom-flatpickr">
					Dates
					<Flatpickr
						options={flatpickrOptions}
						name="dates"
						required
					/>
				</label>
			{/if}
			{#if $_config.governmentRates == "1"}
				<label class="selectContainer">
					Special Rates
					<select bind:value={formData.specialRateType}>
						<option value={null}>None</option>
						<option value="government">Government</option>
						<option value="bookingCode">Booking Code</option>
					</select></label
				>
			{/if}

			{#if $_config.bookingCodeType != "Disabled" && (formData.specialRateType == "bookingCode" || !$_config.governmentRates == "1")}
				<!-- <div class="zenya-form-group"> -->
				<label>
					Booking Code
					<input
						name="bookingCode"
						type="text"
						bind:value={formData.bookingCode}
					/>
					{#if $_config.bookingCodeHint}
						<div class="zenya-field-hint">
							{$_config.bookingCodeHint}
						</div>
					{/if}
				</label>
			{/if}
			<button
				type="submit"
				class="zenya-search-button zenya-booking-button"
				value="Search">Search</button
			>
		</div>
		{#if formError}
			<div class="zenya-form-error">
				{@html formError}
				{#if contactLink}
					<button
						type="button"
						class="zenya-booking-button zenya-search-contact"
						on:click={() => route.set("contact")}>Contact Us</button
					>
				{/if}
			</div>
		{/if}
	</div>
</form>

{#if customResultError}
	<div class="result-error custom">{@html customResultError}</div>
	<div class="zenya-contact-container"><ContactForm /></div>
{:else if resultError}
	<h2 class="result-error">{resultError}</h2>
	<div class="zenya-contact-container"><ContactForm /></div>
{/if}

<script>
	import Flatpickr from "svelte-flatpickr";
	import ContactForm from "./ContactForm.svelte";

	import { onMount, getContext } from "svelte";

	import { bookable, buildings, currentBuilding } from "./RoomStore";
	import { requestInfo } from "./BookingStore";

	import { route, _loading } from "./RoutingStore.js";

	import { _config } from "./ConfigStore";

	import dayjs from "dayjs";

	import GooglePlacesAutocomplete from "@silintl/svelte-google-places-autocomplete";

	let post = getContext("fetch");

	let locations = [];
	let roomTypes = [];

	let customResultError, resultError;

	function onPlaceChanged(e) {
		if (e && e.detail) {
			console.log(e);
			console.log(e.detail.place.geometry.location.lat());
			console.log(e.detail.place.geometry.location.lng());

			formData.location = {
				lat: e.detail.place.geometry.location.lat(),
				long: e.detail.place.geometry.location.lng(),
			};
		} else {
			formData.location = null;
		}
	}

	let gApiKey = "AIzaSyBSPMHKcKRXM27KGZX53-Z_lhqDRVC3FVs";

	onMount(async () => {
		if ($requestInfo && $requestInfo.bookingCode) {
			formData = { ...formData, ...$requestInfo };
		}
		let postResult = await post("getBookingMetadata", {});

		let result = await postResult.json();

		// locations = result.data.locations
		// 	.sort((a, b) => {
		// 		if (a.state == b.state) {
		// 			return a.city.localeCompare(b.city);
		// 		}
		// 		return a.state.localeCompare(b.state);
		// 	})
		// 	.map((l) => ({
		// 		...l,
		// 		label: `${l.city}, ${l.state}`,
		// 		value: `${l.city}, ${l.state}`,
		// 	}));
		roomTypes = result.data.roomTypes.map((r) => ({
			label: r.roomTypeName,
			value: r.roomTypeId,
		}));
	});

	export let formData = {
		location: null,
		distance: "20",
		roomTypeId: null,
		startDate: null,
		endDate: null,
		bookingCode: null,
	};
	// let formData = {
	// 	location: "New York, NY",
	// 	roomTypeId: { value: 444 },
	// 	startDate: null,
	// 	endDate: null,
	// 	bookingCode: "Disney2020",
	// };

	let flatpickrOptions;
	let lastDisabled;

	let unsub = _config.subscribe((res) => {
		if (res && Object.keys(res).length) {
			let start = dayjs();
			if (res.minBookingWindow) {
				start = start.add(res.minBookingWindow, "d");
			}
			let end = start.add(1, "d");
			if (res.minStayLength) {
				end = start.add(res.minStayLength, "d");
			}

			flatpickrOptions = {
				mode: "range",
				dateFormat: "m/d/Y",
				minDate: start.format("M/D/YYYY"),
				enableTime: false,
				defaultDate: [start.format("M/D/YYYY"), end.format("M/D/YYYY")],
				onChange: (selectedDates, dateStr, instance) => {
					if (selectedDates.length > 1) {
						formData.startDate = dayjs(selectedDates[0])
							.hour(12)
							.toDate();
						formData.endDate = dayjs(selectedDates[1])
							.hour(12)
							.toDate();
						// formData.numberNights = Math.round(
						// 	(formData.endDate.getTime() -
						// 		formData.startDate.getTime()) /
						// 		86400000
						// );
						formData.numberNights = dayjs(formData.endDate).diff(
							dayjs(formData.startDate),
							"days",
						);
						if (lastDisabled) {
							instance.set("enable", [lastDisabled]);
						}
					} else {
						formData.startDate = null;
						formData.endDate = null;
						if (lastDisabled) {
							instance.set("enable", [lastDisabled]);
						}
					}
				},
				onReady: (selectedDate, dateStr, instance) => {
					// // console.log("FLATPICKR READY");
					if ($requestInfo && $requestInfo.location) {
						formData = { ...formData, ...$requestInfo };
						// // console.log("SETTING FROM REQUEST");
						instance.setDate(
							[$requestInfo.startDate, $requestInfo.endDate],
							true,
						);
					} else {
						// // console.log("SETTING DEFAULT");
						instance.setDate(
							[start.format("M/D/YYYY"), end.format("M/D/YYYY")],
							true,
						);
					}
				},
			};
		}
	});

	let formError;
	let contactLink = false;

	async function getSearchResults(e) {
		contactLink = false;
		formError = null;
		customResultError = null;
		resultError = null;

		/// HANDLE REQUIRED FIELDS
		if (
			formData.location &&
			// formData.location.value &&
			// formData.roomTypeId &&
			// formData.roomTypeId.value &&
			formData.startDate &&
			formData.endDate &&
			(($_config.bookingCodeType == "Required" && formData.bookingCode) ||
				$_config.bookingCodeType != "Required")
		) {
			let days =
				dayjs(formData.endDate).diff(formData.startDate, "days", true) +
				1;
			let daysAhead = dayjs(formData.startDate).diff(dayjs(), "days") + 1;

			/// HANDLE CONFIGURATION OPTIONS
			if (
				$_config.allowBookingAfter &&
				daysAhead < $_config.allowBookingAfter
			) {
				formError = $_config.minBookingWindowMessage
					? $_config.minBookingWindowMessage
					: `Your stay must start a minimum of ${$_config.allowBookingAfter} days from now`;
				if ($_config.minBookingWindowContactLink == "Include") {
					contactLink = true;
				}
			} else if (
				$_config.minStayLength &&
				days < $_config.minStayLength
			) {
				formError = $_config.minStayLengthMessage
					? $_config.minStayLengthMessage
					: `Your stay must be a minimum of ${$_config.minStayLength} days`;
				if ($_config.minStayLengthContactLink == "Include") {
					contactLink = true;
				}
			}

			/// IF NO ERROR, SSUBMIT REQUEST
			// if (!formError || !formError.length) {
			let formCopy = { ...formData };
			// formCopy.location = formData.location.value;
			// formCopy.roomTypeId = formData.roomTypeId.value;
			_loading.set(true);
			let postResult = await post("getRoomsByLocations", formCopy);
			_loading.set(false);
			// console.log(postResult);
			if (postResult.ok) {
				let result = await postResult.json();
				result = result.data;
				// console.log(result);

				if (formData.bookingCode) {
					bookable.set(true);
				} else {
					bookable.set(false);
				}

				formError =
					// formData.bookingCode &&
					!result.validCode
						? $_config.invalidCodeMessage || "Invalid Booking Code"
						: formError;
				if (
					!result.validCode &&
					$_config.invalidCodeContactLink == "Include"
				) {
					contactLink = true;
				}

				requestInfo.set({
					...formData,
					validCode: result.validCode,
					_company: result.company,
				});

				buildings.set(result.buildings);

				/// UPDATE CONFIG WITH BOOKING CODE
				let configPost = await post("getBookingConfig", {
					bookingCode: formData.bookingCode,
				});
				let configResult = await configPost.json();
				if (configResult && configResult.data) {
					_config.set(configResult.data);
				}

				route.set("buildings");

				// }
			} else {
				let error = await postResult.json();
				buildings.set([]);
				if (error && error.error) {
					requestInfo.set({ ...formData });
					if (error.error == "No locations found") {
						console.error(error);
						route.set("buildings");
					} else {
						route.set("search");
						if ($_config.searchError) {
							customResultError = $_config.searchError;
						} else {
							resultError = error.error;
						}
					}
				}
			}
			// }
		} else {
			formError = "All fields are required";
		}
	}

	const locationGroups = (group) => group.state;
	const locationLabel = (option) => `${option.city}, ${option.state}`;
</script>

<style type="text/scss">
	.zenya-search-form {
		padding-bottom: 0.5em;
		border-bottom: 2px solid #ccc;
		margin: 0.5em;
		margin-bottom: 1em;

		.zenya-search-text-container {
			padding: 6px;
		}

		.zenya-search-container {
			// display: flex;
			// flex-direction: row;
			// flex-wrap: wrap;

			.zenya-form-group {
				--background: transparent;
				--border: 0;
				--height: 27px;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				flex: 2;
				min-width: 171px;
			}

			label {
				.selectContainer {
					--background: transparent;
					--border: 0;
					--height: 27px;
				}
				&.selectContainer {
					--background: transparent;
					--border: 0;
					--height: 27px;
				}

				--background: transparent;
				--border: 0;
				--height: 27px;

				flex: 1;
				min-width: 170px;
				margin: 0 5px;
				margin-bottom: 7px;
			}

			.zenya-form-error {
				color: red;
				padding: 3px 10px;
			}
		}
		button[type="submit"] {
			margin: 0 5px;
			margin-bottom: 7px;
		}
	}
	.result-error {
		text-align: center;

		&.custom {
			font-size: 1.1em;
		}
	}
	.zenya-contact-container {
		padding: 10px;
	}
</style>
