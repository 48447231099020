<div>
	<div class="zenya-guest-container">
		{#if $guests.length == 0}
			<h4>
				{$_config.addGuestPrompt
					? $_config.addGuestPrompt
					: "Please add a guest to continue"}
			</h4>
		{/if}
		<h2>Guests</h2>
		{#each $guests as guest}
			<div class="zenya-guest-entry">
				<div class="zenya-guest-icons">
					{#if guest.type == "both" || guest.type == "guest"}
						<!-- SHOW GUEST ICON -->
						<img src="{envURL}assets/person-icon.svg" />
					{/if}
					{#if guest.type == "both" || guest.type == "payor"}
						<!-- SHOW CC ICON -->
						<img src="{envURL}assets/payment-icon.svg" />
					{/if}
					<button
						type="button"
						class="zenya-booking-button zenya-base-button zenya-guest-list-edit"
						on:click|preventDefault={() => openStripe(guest)}
						>Edit</button
					>
				</div>
				<div class="zenya-guest-details">
					<h3>
						{guest.firstName}
						{guest.lastName}
						{#if guest.ccType}
							<small>({guest.ccType})</small>
						{/if}
					</h3>
					<div class="zenya-guest-contact">
						{guest.email}
					</div>
					<div class="zenya-guest-contact">
						{guest.phone}
					</div>
					{#if guest.address}
						<div class="zenya-guest-address">
							<div>{guest.address}</div>
							<div>{guest.city}, {guest.state} {guest.zip}</div>
						</div>
					{/if}
				</div>
			</div>
		{/each}
		<button
			type="button"
			class="zenya-booking-button zenya-guest-list-list"
			on:click={() => openStripe()}>Add</button
		>
	</div>
</div>

{#if modalState}
	<BookingGuestModalComponent on:close={closeStripe} bind:guest={editGuest} />
{/if}

<script>
	import { getContext, onMount } from "svelte";

	import BookingGuestModalComponent from "./BookingGuestModalComponent.svelte";
	import { _loading } from "./RoutingStore";
	import { _config, _metadata } from "./ConfigStore";

	import { requestInfo, guests } from "./BookingStore";
	guests.reset();

	let envURL = getContext("envURL");
	let post = getContext("fetch");

	let editGuest;

	let modalState = false;

	onMount(async () => {
		// 	guests.reset();
		// 	calculateFees();
		_loading.set(true);
		let postResult = await post("bookingMetadata", {
			bookingCode: $requestInfo.bookingCode,
		});
		_loading.set(false);

		if (postResult.ok) {
			let result = await postResult.json();

			_metadata.set(result.data);

			/// ADD TO STORE
			if (
				result.data &&
				result.data.companyContacts &&
				result.data.companyContacts.length
			) {
				result.data.companyContacts.forEach((c) => {
					guests.saveGuest({
						...c,
						type: "payor",
					});
				});
			}

			// paymentFrequency = result.data.paymentFrequency;
			// if (paymentFrequency && paymentFrequency.invoiceitemscheduleId) {
			// 	frequencyAvailability();
			// 	if (
			// 		frequencyAvailable &&
			// 		$_config.companyFrequencyDefault == "Enabled"
			// 	) {
			// 		selectedFrequency = paymentFrequency.valuename;
			// 	}
			// }
		} else {
			console.error(await postResult.json());
		}
	});

	export const openStripe = (edit) => {
		if (edit) {
			editGuest = edit;
		}
		modalState = true;
	};

	function closeStripe() {
		modalState = false;
		editGuest = null;
	}
</script>

<style lang="scss">
	.zenya-guest-container {
		padding-bottom: 15px;

		.zenya-guest-entry {
			background: whitesmoke;
			border: 1px solid lightgray;
			border-radius: 4px;
			padding: 10px;
			display: flex;
			flex-direction: row;

			margin-bottom: 15px;

			.zenya-guest-icons {
				padding-top: 5px;
				position: relative;
				min-width: 60px;
				min-height: 60px;

				& > img {
					width: 22px;
					height: 22px;
				}
				& > button.zenya-booking-button.zenya-base-button {
					position: absolute;
					bottom: 0;
					left: 0;
				}
			}

			.zenya-guest-details {
				padding: 0 15px;
				& > h3 {
					margin: 0;
				}
				.zenya-guest-contact {
					font-size: 0.9em;
					font-weight: bolder;
					color: gray;
				}
				.zenya-guest-address {
					font-size: 0.8em;
				}
			}
		}
	}
</style>
