import App from './App.svelte';
import {analytics} from "./SentryAnalytics";


// var zenyaData = {
// 	url: "http://localhost:3030/api/v1/mapping/",
// 	customerKey: scriptEl.dataset.widgetKey,
// 	options: {
// 		method: "POST",
// 		mode: "no-cors",
// 		cache: "no-cache",
// 		credentials: "omit", // include, *same-origin, omit
// 		headers: {
// 			"Content-Type": "application/json",
// 		},
// 		// redirect: 'follow', // manual, *follow, error
// 		referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
// 	},
// }

let app;
function bootApp() {
	let scriptEl = document.querySelector('#zenya-booking-widget');
	let widgetContainer = document.querySelector('#zenya-booking-container');

	if (scriptEl && !widgetContainer && !app) {
		scriptEl.insertAdjacentHTML('afterend', '<div id="zenya-booking-container"></div>');

		let customStyles = document.querySelector('#zenya-custom-styles');
		analytics.init();
		app = new App({
			target: document.querySelector('#zenya-booking-container'),
			props: {
				widgetKey: scriptEl.dataset.widgetKey,
				env: scriptEl.dataset.env || 'prod',
				customStyles: customStyles ? true : false,
				defaultLocation: scriptEl.dataset.defaultLocation,
			}
		});
	} else if (!scriptEl && app) {
		// console.log('WIDGET CLOSED');

		app = null;
	} else if (!scriptEl && !app) {
		// console.log('WIDGET NOT VALID');
	}
}
setInterval(function () {
	bootApp();
}, 1000);

export default App;