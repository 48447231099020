<div>
	<div class="zenya-option-container">
		<div class="zenya-option-ranking-layout">
			<div class="zenya-option-ranking-select">
				{#each $options.deals as option, index}
					<div
						class="zenya-option-ranking-item"
						on:click={selectOption(option)}
					>
						<div class="zenya-option-ranking-item-image">
							<img
								src={option.buildings[0].rooms[0].roomImages[0]}
							/>
						</div>
						<div class="zenya-option-ranking-item-details">
							<h3>{option.LocationNumber}</h3>
							<p>{option.ranking}</p>
						</div>
					</div>
				{/each}
			</div>
			<div class="zenya-option-ranking-details">
				{#if $currentBuilding && $currentRoom}
					<!-- content here -->
					<RoomDetails />
				{/if}
			</div>
		</div>
	</div>
	<pre>
	{JSON.stringify($options)}
</pre>
</div>

<script>
	import { _config } from "./ConfigStore";
	import { currentBuilding, currentRoom, options } from "./RoomStore";
	// import dayjs from "dayjs";
	// import currency from "currency.js";

	import RoomDetails from "./RoomDetails.svelte";

	function selectOption(option) {
		currentBuilding.set(option.buildings[0]);
		currentRoom.set(option.buildings[0].rooms[0]);
	}
</script>

<style type="text/scss">
	.zenya-option-container {
		container-type: inline-size;

		.zenya-option-ranking-layout {
			display: grid;
			grid-template-columns: 300px 1fr;
			grid-gap: 10px;
		}
	}
</style>
