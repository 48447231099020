<h2>{$currentBuilding.buildingName}</h2>
{#if $_config.showRates == "Always" || ($_config.showRates == "Booking Code Entered" && $requestInfo.bookingCode && $requestInfo.validCode)}
	<label class="zenya-price-filter">
		Price Range
		<div class="zemya-price-filter-wrapper">
			<RangeSlider
				range="true"
				bind:values={selectedRange}
				float="true"
				min={possibleMin}
				max={possibleMax}
			/>
		</div>
	</label>
{/if}
<div class="zenya-result-grid">
	{#each displayRooms as res, i (res.roomTypeId)}
		<div class="zenya-card" in:fade={{ duration: 400, delay: i * 100 }}>
			<div class="zenya-card-image">
				{#if res.roomImages && res.roomImages.length}
					<Carousel perPage={1}>
						<span class="control" slot="left-control">
							<ChevronLeftIcon />
						</span>
						{#each res.roomImages as image, i (image)}
							<div class="aspect-ratio-box">
								<img
									style="object-fit: cover;"
									src={image}
									alt="{res.name}{i}"
								/>
							</div>
						{/each}
						<span class="control" slot="right-control">
							<ChevronRightIcon />
						</span>
					</Carousel>
				{/if}
			</div>
			<div class="zenya-tile-content">
				<div class="zenya-tile-title">{res.roomType}</div>
				<!-- <div class="zenya-tile-subtitle">{res.name}</div> -->
				{#if res.roomDescription}
					<p class="zenya-tile-description">{res.roomDescription}</p>
				{:else}
					<p class="zenya-tile-description" />
				{/if}

				<div class="zenya-tile-details">
					<!-- ${res.price}/night -->

					{#if res.price && ($_config.showRates == "Always" || ($_config.showRates == "Booking Code Entered" && $requestInfo.bookingCode))}
						<div class="zenya-tile-details">
							{#if $_config.rateDisplay == "Original Rate Slashed Out" && res.originalPrice && res.originalPrice != res.price}
								<span class="zenya-slashed-price"
									>{currency(
										res.originalPrice
									).format()}</span
								>
							{/if}{currency(res.price).format()}/{res.timeframe}
							{#if $_config.priceSuffix}
								<div class="zenya-price-suffix">
									{$_config.priceSuffix}
								</div>
							{/if}
						</div>
					{/if}
				</div>

				<div class="zenya-tile-actions">
					<button
						class="zenya-booking-button"
						on:click={roomDetails(res)}>View Details</button
					>
					{JSON.stringify(res)}
					{#if res.price && !res.requestOnly && ($_config.showRates == "Always" || ($_config.showRates == "Booking Code Entered" && $requestInfo.bookingCode))}
						<button
							class="zenya-booking-button"
							on:click={bookRoom(res)}
							id="results-book"
						>
							Book Now
						</button>
					{:else}
						<button
							class="zenya-booking-button"
							on:click={contact(res)}
							id="results-contact">Request</button
						>
					{/if}
				</div>
			</div>
		</div>
	{/each}
</div>
<UnavailableComponent bind:this={unavailable} />

<script>
	import { getContext } from "svelte";
	import Carousel from "@beyonk/svelte-carousel";
	import { ChevronLeftIcon, ChevronRightIcon } from "svelte-feather-icons";
	import { fade } from "svelte/transition";

	import { bookable, currentBuilding, currentRoom } from "./RoomStore";
	import { route, _loading } from "./RoutingStore";
	import { _config } from "./ConfigStore";
	import { requestInfo, blockedReservation } from "./BookingStore";

	import RangeSlider from "svelte-range-slider-pips";

	import dayjs from "dayjs";
	import currency from "currency.js";
	import UnavailableComponent from "./UnavailableComponent.svelte";

	let unavailable;

	let post = getContext("fetch");

	let possibleMin = $currentBuilding.rooms.reduce(
		(min, r) => (r.price < min ? r.price : min),
		0
	);
	let possibleMax = $currentBuilding.rooms.reduce(
		(max, r) => (r.price > max ? r.price : max),
		0
	);
	let selectedRange = [possibleMin, possibleMax];
	$: displayRooms = filterRooms(...selectedRange);

	function roomDetails(e) {
		currentRoom.set({ ...e });
		route.set("roomdetails");
	}

	// function bookRoom(e) {
	// 	currentRoom.set({ ...e });
	// 	// console.log($currentRoom);
	// 	route.set("booking");
	// }
	async function bookRoom(room) {
		let nights =
			Math.round(
				dayjs($requestInfo.endDate).diff(
					$requestInfo.startDate,
					"days",
					true
				)
			) + 1;

		_loading.set(true);
		let postResult = await post("blockReservation", {
			checkIn: $requestInfo.startDate,
			checkOut: $requestInfo.endDate,
			numberNights: nights,
			roomId: room.roomId,
			roomNumber: room.name,
		});
		_loading.set(false);

		let result = await postResult.json();

		// if (postResult.ok ) {
		if (postResult.ok && result.data.available == true) {
			// console.log(result);

			blockedReservation.set(result.data);
			currentRoom.set({ ...room });
			route.set("booking");
		} else if (postResult.ok && result.data.available == false) {
			unavailable.setModalState(true);
			console.warn(
				"This unit is no longer available. Please try another"
			);
		} else {
			console.error(result);
		}
	}

	function contact(e) {
		currentRoom.set({ ...e });
		route.set("contact");
	}

	function filterRooms(min, max) {
		let filteredRooms = $currentBuilding.rooms.filter(
			(r) => (r.price >= min && r.price <= max) || !r.price
		);
		return filteredRooms;
	}
</script>

<style type="text/scss">
	.zenya-result-grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
		grid-gap: 1rem;
	}
	.zenya-price-filter {
		margin-bottom: 10px;
		.zemya-price-filter-wrapper {
			margin-top: 20px;
		}
	}
</style>
