import { writable } from 'svelte/store';


/// SIMPLE ROUTING
function router(defaultRoute) {
	/// CALLABLE FUNCTIONS FOR WRITABLE STORE
	const { subscribe, set, update } = writable(defaultRoute);

	/// HOLD ROUTE LIST
	let routeHistory = [];

	/// RETURN NEW STORE OBJECT
	return {
		subscribe,
		/// OVERWRITE STORE FUNCTION WITH CUSTOM FUNCTIONALITY
		set: (r) => {
			/// ADD ROUTE TO HISTORY
			if (!r) {
				r = 'search';
			}
			routeHistory.push(r);
			/// UPDATE CURRENT ROUTE
			set(r);
		},
		/// ADD NEW CUSTOM FUNCTIONALITY TO THE STORE
		back: () => {
			/// REMOVE CURRENT ROUTE FROM HISTORY
			routeHistory.pop();
			/// GET PREVIOUS ROUTE FROM HISTORY ARRAY
			let route = routeHistory[routeHistory.length - 1];
			/// UPDATE CURRENT ROUTE TO PREVIOUS ROUTE
			if (!route) {
				route = 'search';
			}
			set(route);
		},
		length: () => {
			return routeHistory.length;
		},
		last: () => {
			return routeHistory[routeHistory.length - 1];
		},
	};
}

export const route = router();

export const _loading = writable(false);