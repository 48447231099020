<div class="contact-layout">
	{#if !successfulSubmit}
		<form on:submit|preventDefault={contact}>
			{#if $_config.contactFormMessage && $requestInfo._company && $requestInfo._company.temporaryHoldButton == 1}
				<p>
					{$_config.contactFormMessage}
				</p>
			{/if}

			<div class="zenya-form-group">
				<label>
					First Name
					<input
						name="firstName"
						type="text"
						required
						bind:value={formData.firstName}
					/>
				</label>
				<label>
					Last Name
					<input
						name="lastName"
						type="text"
						required
						bind:value={formData.lastName}
					/>
				</label>
			</div>
			<div class="zenya-form-group">
				<label>
					Phone
					<input
						name="phone"
						type="tel"
						required
						bind:value={formData.mobilePhone}
					/>
				</label>

				<label>
					Email
					<input
						name="email"
						type="email"
						required
						bind:value={formData.email}
					/>
				</label>
			</div>
			{#if contactFields && contactFields.length}
				{#each contactFields as field}
					<div class="zenya-form-group">
						<label>
							{field.tagName}
							<input
								type="text"
								required={field.isRequired}
								name={field.tagName + field.tagId}
								bind:value={field.tagvalue}
							/>
						</label>
					</div>
				{/each}
			{/if}

			<div class="zenya-form-group">
				<label>
					Message
					<textarea
						name="message"
						required
						bind:value={formData.Question}
					/>
				</label>
			</div>
			<div class="submit-row">
				<button
					class="zenya-booking-button"
					type="submit"
					on:click={tracking}>Submit</button
				>
			</div>
		</form>
		{#if formError}
			<div class="error">{formError}</div>
		{/if}
	{:else}
		<div>Thank you! We'll be in touch with you soon!</div>
	{/if}
</div>

<script>
	import { currentRoom, currentBuilding } from "./RoomStore";
	import { requestInfo } from "./BookingStore";
	import { getContext, onMount } from "svelte";
	import { _config } from "./ConfigStore";
	import dayjs from "dayjs";
	import { _loading } from "./RoutingStore";
	let post = getContext("fetch");

	let formData = {
		Question: ``,
	};

	let successfulSubmit, formError;

	let contactFields = $_config.contactFields;

	onMount(() => {
		formData = {
			Question: `Hello, I am interested in booking a`,
		};
		if (
			$currentRoom &&
			$currentRoom.roomType &&
			$currentBuilding &&
			$currentBuilding.buildingName
		) {
			formData.Question += `${$currentBuilding.buildingHideRoomType == "Yes" ? "" : ` ${$currentRoom.roomType}`} ${(
				$_config.roomAlt || "room"
			).toLowerCase()} at ${$currentBuilding.buildingName} `;

			if ($_config.includeLocationInRequestMessage == "Include") {
				formData.Question += `in ${$currentBuilding.addressObj.city}, ${$currentBuilding.addressObj.state} `;
			}
		} else {
			formData.Question += `${$_config.roomAlt || "room"} `;
		}
		if ($requestInfo && $requestInfo.startDate && $requestInfo.endDate) {
			formData.Question += `between ${dayjs(
				$requestInfo.startDate,
			).format("dddd, MMMM D, YYYY")} and ${dayjs(
				$requestInfo.endDate,
			).format("dddd, MMMM D, YYYY")}`;
		}
	});

	async function contact() {
		formError = null;
		if (
			formData.firstName &&
			formData.lastName &&
			formData.mobilePhone &&
			formData.email &&
			formData.Question
		) {
			if (
				$requestInfo._company &&
				$requestInfo._company.temporaryHoldButton == 1
			) {
				formData.Question +=
					"\n\n*This is requested as a temporary hold*";
			}

			formData = {
				...formData,
				startDate: $requestInfo.startDate,
				endDate: $requestInfo.endDate,
				labels:
					contactFields && contactFields.length
						? contactFields.map((cf) => ({ ...cf }))
						: [],
			};
			_loading.set(true);
			let postResults = await post("submitContact", formData);
			let result = await postResults.json();
			_loading.set(false);
			if (postResults.ok) {
				// console.log(result);
				successfulSubmit = true;
			} else {
				console.error(result);
			}
		} else {
			formError = "All fields are required";
		}
	}

	function tracking() {
		if (window.goog_report_conversion) {
			let currentURL = window.location.href;
			goog_report_conversion(currentURL);
		}
	}
</script>

<style type="text/scss">
	/* your styles go here */
	.zenya-form-group {
		display: flex;

		label {
			flex: 1;
			margin: 5px;

			textarea {
				font-family: inherit;
			}
		}
	}
	.submit-row {
		margin: 5px;
	}
</style>
