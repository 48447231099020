import { writable } from 'svelte/store';

export const bookable = writable(false);

export const buildings = writable();

export const currentBuilding = writable();

export const currentRoom = writable();

export const options = writable();